import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, TextField, Box, Alert, useTheme, Button, Chip ,Pagination, Avatar, Dialog, Menu, MenuItem, Divider } from "@mui/material";
import { AddPhotoAlternate, Delete, DetailsOutlined, MoreOutlined } from '@mui/icons-material';
import { DataGrid } from "@mui/x-data-grid";
import Header from 'src/components/Header';
import ButtonRadios from "src/components/ButtonRadios";
import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'src/utils/axiosInstance';
import moment from 'moment';
import useSelectFields from './useSelectFields';
import FormItem from '../properties/formItem';
import { asset, toGamel } from 'src/utils/Utils';
import { tokens } from "src/theme";
import useTopBarSearch from 'src/hooks/useTopBarSearch';
import { useBookings, useFormSubmit } from 'src/services/bookings';
import ImagePreview from 'src/components/Uploader/ImagePreview';
import PropertyImageCard from 'src/pages/properties/ImageCard';
import useToast from 'src/hooks/useToast';
import MyTable from "src/components/MyTable"
import BookingDetail from './detail';

// const mockData = [
//   { id: 1, images: [{ image_data: '../../assets/cat.jpg' }], created_at: '2024-05-01T08:00:00.999', type: 'DISCOUNT', title: 'Lizard', desc: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica' }
// ]

export default function Bookings() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toast = useToast();

  const [previewImages, setPreviewImages] = useState([])

  const [types, setTypes] = useState({
    booking_status: '',
    check_in_status: ''
  })

  const typesFields = useSelectFields({ 
    fields: Object.keys(types),
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })
  

  // console.log(typesFields)

  // const bookingTypes = ['-', 'DISCOUNT', 'EVENTS', 'OTHER']
  // const [bookingType, setBookingType] = useState('-')
  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    // console.log(keyword)
    setSearchKeyword(keyword?.trim() || '')
  })

  const { paginate, setPaginate, isLoading, error, data = { rows: [], count: 0 } } = useBookings({
    ...types,
    searchKeyword
  })

  // console.log(data)
  const { rows, count } = data

  useEffect(() => {
    if (!isLoading && count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  if (error) {
    toast(error?.message || 'Load bookings failed')
  }

  const [currentBooking, setCurrentBooking] = useState(null)

  const mutation = useFormSubmit()
  const handleCheckInStatusUpdate = (e, id) => {
    mutation.mutate({ id, check_in_status: e.target.value }, {
      onSuccess() {
        for (let row of rows) {
          if (row.id === id) {
            row.check_in_status = e.target.value
            break
          }
        }
      }
    })
  }
  const [bookingStatusAnchorEl, setBookingStatusAnchorEl] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", flex: .5 },
    { field: "user", headerName: "User", flex: 2.5, minWidth: 110, cellClassName: 'content-wrap',
      renderCell: ({ row: { user } }) => 
        user ? <>
          <Box>{user.username}</Box>
          <Box>Role: {user.role}</Box>
          <Box>Mobile No:{user.phone}</Box>
          <Box style={{ color: '#4fc1ff' }}>Email: {user.email}</Box>
        </>
        : '-'
      ,
    },
    { field: "property", headerName: "Property", flex: 2, minWidth: 100, cellClassName: 'content-wrap',
      renderCell: ({ row: { property } }) => 
        property 
          ? <>
            {/* <Avatar variant='square' src={ asset(property.images[0]?.image_url) } sx={{ width: '60px', height: '40px' }} 
              onClick={ e => setPreviewImages( property.images.map(t => ({ url: asset(t.image_url) }) ) ) }
              />
            <Box>{property.property_name}</Box> */}
            <PropertyImageCard width="80px" imageHeight="40px" previewImage property={property}/>
          </>
          : ''
      ,
    },
    { field: "image", headerName: "Image", flex: 1, cellClassName: 'content-wrap',
      renderCell: ({ row }) => row.image 
        ? <Avatar variant='square' src={ asset(row.image.image_url) } style={{ cursor: 'pointer' }}
            onClick={ e => setPreviewImages([{ url: asset(row.image.image_url) }]) }
          > <AddPhotoAlternate/> </Avatar>
        : '-'
    },
    { field: "total_price", headerName: toGamel('total_price'), flex: 1.5, minWidth: 80, cellClassName: "name-column--cell",
      renderCell: ({row}) => row.total_price + `(${row.currency})`
    },
    { field: "number_of_guests", headerName: toGamel('number_of_guests'), minWidth: 120, flex: 1.5 },
    // { field: "gender", headerName: "Gender", flex: 1, 
    //   renderCell: ({row}) => row.gender || <span title="unset">🙅🏼‍♀️</span>
    // },
    { field: "guest", headerName: "Guest", flex: 2, minWidth: 140, cellClassName: 'content-wrap',
      renderCell: ({ row }) => 
      <>
        <Box>{row.guest_first_name} {row.guest_surname}</Box>
        <Box>ID/Passport: {row.guest_id_or_passport}</Box>
        <Box>Mobile No:{row.guest_mobile_no}</Box>
        <Box style={{ color: '#4fc1ff' }}>Email: {row.guest_email}</Box>
      </>
      ,
    },
    
    { field: "booking_status", headerName: toGamel('booking_status'), minWidth: 120,
      renderCell: ({ row: { id, booking_status } }) => {
        // 'RESERVED','PAYMENT_PENDING','CONFIRMED','CANCELLED','PAYMENT_FAILED'
        const icon = { RESERVED: '⏯', PAYMENT_PENDING: '⏳', CONFIRMED: "✅", CANCELLED: "⛔", PAYMENT_FAILED: "📵" }[booking_status]
        return (
          <Chip data-booking_status={booking_status} data-booking_id={id} sx={{cursor: 'pointer', '&:hover': { border: `1px solid ${colors.red[400]}` } }} 
            title={booking_status} variant="outlined" color={ 'warning' } size="small" label={ booking_status + ' ' + icon }
            onClick={ e => setBookingStatusAnchorEl(e.currentTarget) }
          />
        );
      },
    },
    { field: "date", headerName: "Date Start/End", width: 100, cellClassName: 'content-wrap',
      renderCell: ({ row }) => <Box title="Date">
        <Box>{ moment(row.date_start).format('YYYY-MM-DD') } </Box>
        <Box textAlign={'center'}>~</Box>
        <Box>{ moment(row.date_end).format('YYYY-MM-DD') }</Box>
      </Box>
    },
    { field: "check_in", headerName: toGamel('check_in_time') + '/Status', width: 165, cellClassName: 'content-wrap',
      renderCell: ({ row: { id, check_in_time, check_in_status } }) => {
        const icon = { CHECKED_IN: '✅', CHECKED_OUT: '🔚', NOT_CHECKED_IN: "⬜" }[check_in_status] || ''
        const statusOption = typesFields.find(t => t.field === 'check_in_status')
        statusOption.options = statusOption.options.filter(t => !!t.value)
        
        return <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box fontWeight={'bold'} color={colors.redAccent[300]}>{ check_in_time }</Box>
          <Box>
            {/* { check_in_status && <Chip title={check_in_status} variant="outlined" color={ 'info' } size="small" label={ check_in_status + ' ' + icon }/> } */}
            <FormItem { ...statusOption } onChange={ e => handleCheckInStatusUpdate(e, id) } label="Status" sx={{ width: '100%' }} size="small" type="select" variant="outlined" value={check_in_status} />
          </Box>
        </Box>
      },
    },
    { field: "booking_date", headerName: toGamel('booking_date'), minWidth: 90, 
      renderCell: ({ row }) => <Box>
        <Box>{ moment(row.booking_date).format('YYYY-MM-DD') } </Box>
      </Box>
    },
    { field: "actions", headerName: "Actions", flex: 1.5, minWidth: 90,
      renderCell: ({ row, index, column }) => (
        <Box lineHeight={1.4} display={'flex'} flexDirection={'column'} justifyContent={'center'} height="100%" gap={'10px'} sx={{ '& .pointer': { cursor: 'pointer' } }}>
          <Chip icon={<MoreOutlined />} variant="filled" color="success" label="Detail" size="small" className="pointer" onClick={e => setCurrentBooking(row) }/>
          { row.booking_status !== 'CANCELLED' && 
            <Chip variant="outlined" color="error" label="Cancel" size="small" className="pointer" onClick={e => 
              window.confirm('Confirm to cancel the booking') && handleBookingStatusUpdate('CANCELLED', row.id) 
            }/>
          }
        </Box>
      ),
    },

    // { field: "role", headerName: "Role", flex: 1,
    //   renderCell: ({ row: { role } }) => {
    //     const icon = { ADMIN: <AdminPanelSettingsOutlinedIcon color="error"/>, USER: <LockOpenOutlinedIcon /> }[role]
    //     const color = { ADMIN: 'warning', role: 'info' }[role]
    //     return (
    //       <Chip title={role} color={ color } size="small" label={ role } icon={ icon }/>
    //     );
    //   },
    // },
  ]

  const handleBookingStatusUpdate = (t, id) => {
    const booking_id = id || bookingStatusAnchorEl?.dataset?.booking_id
    if (!+booking_id) {
      return toast('invalid booking id');
    }
    mutation.mutate({ id: +booking_id, booking_status: t }, {
      onSuccess() {
        for (let row of rows) {
          if (row.id === id) {
            row.booking_status = t
            break
          }
        }
      }
    })

    setBookingStatusAnchorEl(null)
  }

  const prefix = 'booking-status'

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          sx={{ flex: 1, marginBottom: 0 }}
          title="Bookings"
          subtitle="List of Bookings"
          />
        
        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'}>
          { Object.values(types).filter(Boolean).length > 0 && 
            <Button color="error" variant="outlined" onClick={ e => setTypes({}) }>Clear Filters ❌</Button> 
          }
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '140px' }} name={t.field} onChange={t.onChange}/>
          )}
        </Box>

        <Link to={'/booking-form'} style={{ textDecoration: "none" }}>
          <Button color="success" variant="contained" sx={{ marginRight: "12px" }} >
            Add a Booking
          </Button>
        </Link>
      </Box>
      
      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        rowHeight={95}
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      { previewImages.length ? <ImagePreview files={previewImages} onClose={ e => setPreviewImages([]) } currentIndex={0}/> : '' }

      <Dialog open={ !!currentBooking } onClose={ e => setCurrentBooking(null) }>
        <Box padding={2}>
          { !!currentBooking && <BookingDetail booking={ currentBooking }/> }
        </Box>
      </Dialog>

      <Menu component="nav"
        id={ `${prefix}-menu` }
        MenuListProps={{
          'aria-labelledby': `${prefix}-button`,
        }}
        anchorEl={bookingStatusAnchorEl} 
        open={ !!bookingStatusAnchorEl }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={ e => setBookingStatusAnchorEl(null) }
        >
        { !!bookingStatusAnchorEl && 
          <Typography variant="h5" sx={{ padding: 1 }} color={colors.blueAccent[200]} fontWeight={'bold'}>
            Update Booking Status
          </Typography> 
        }
        { typesFields.find(t => t.field === 'booking_status').options.map((t, i) => 
          ['CONFIRMED', 'CANCELLED'].includes(t.value) && <Box key={i} padding={1} sx={{ '& .MuiMenuItem-root.Mui-selected': { backgroundColor: colors.grey[400] } }}>
            { i > 0 && <Divider/> }

            <MenuItem key={t.name} 
              selected={ t.value === bookingStatusAnchorEl?.dataset?.booking_status }
              onClick={e => handleBookingStatusUpdate(t.value) }
              >
              { t.value }
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
}