import HttpService from "./HttpService";

export const LoginService = ({ email, password }) => {
  const http = new HttpService();
  const url = "/api/users/login";
  // ! unconnected 🎃
  return http.postData({ email, password, role: ['ADMIN', 'COUNTER'] }, url);
  // mock
  return Promise.resolve({
    status: 200,
    data: {
      token: 'abc123',
      // user: payload
    }
  })
};