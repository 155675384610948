import { Box, Button, IconButton, Typography, useTheme, Divider, Link } from "@mui/material";
// import PieChart from "src/components/PieChart";
import { tokens } from "../../theme";
import { mockBookings } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
import { BarChart, PieChart } from "@mui/x-charts";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import * as BookingService from "src/services/bookings";
import * as FeedbackService from "src/services/feedbacks";
import moment from "moment";

import * as PropertyService from 'src/services/properties';
import * as UserService from 'src/services/users';
import * as PayoutService from 'src/services/payouts';
import { useNavigate } from "react-router-dom";
import { plus, mul } from "src/utils/money";
import { HourglassEmpty, House, MoneyOffCsred } from "@mui/icons-material";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const booking_statuss = ['RESERVED', 'PAYMENT_PENDING', 'CONFIRMED', 'CANCELLED'];
  const period_types = ['SHORT', 'LONG']
  const check_in_statuss = ['CHECKED_IN', 'CHECKED_OUT', 'NOT_CHECKED_IN']
  const { data: { rows: bookings = [], count: bookingCount = 0 } = {} } = BookingService.useBookings()
  const { data: bookingCountByStatus = {} } = BookingService.useCount({ booking_status: JSON.stringify(booking_statuss) })
  const { data: bookingCountByPeriod = {} } = BookingService.useCount({ period_type: JSON.stringify(period_types) })
  const { data: bookingCountByCheckIn = {} } = BookingService.useCount({ check_in_status: JSON.stringify(check_in_statuss) })

  const { data: propertyCount = 0 } = PropertyService.useCount({ })

  const feedback_statuss = ['PENDING','RESOLVED','REPLIED']
  const feedback_types = ['DEFECT','MANAGEMENT','OTHER']
  const { data: feedbackCountByStatus = {} } = FeedbackService.useCount({ status: JSON.stringify(feedback_statuss) })
  const { data: feedbackCountByType = {} } = FeedbackService.useCount({ type: JSON.stringify(feedback_types) })

  const user_types = ['OWNER','AGENT','TENANT']
  const { data: userCount = {} } = UserService.useCount({ user_type: JSON.stringify(user_types) }) 

  const payout_statuss = ['DUE', 'PAID']
  const { data: payoutSumByDUE = 0 } = PayoutService.useSum('total_payout', { 
    payout_year_month: moment().format('YYYY-MM'), 
    payout_status: payout_statuss[0]
  }) 
  const { data: payoutSumByPAID = 0 } = PayoutService.useSum('total_payout', { 
    payout_year_month: moment().format('YYYY-MM'), 
    payout_status: payout_statuss[1]
  })
  const payoutPaidProgress = plus(payoutSumByDUE, payoutSumByPAID) > 0 
    ? (payoutSumByPAID / plus(payoutSumByDUE, payoutSumByPAID))
    : 0

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={ '$' + payoutSumByPAID }
            subtitle="Payouts PAID"
            progress={ payoutPaidProgress }
            increase={ mul(payoutPaidProgress, 100) + '%' }
            icon={
              <MoneyOffCsred
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={propertyCount}
            subtitle="Properties Count"
            progress={false}
            icon={
              <House
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          padding={2}
          backgroundColor={colors.primary[400]}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          {/* <StatBox
            title="32,441"
            subtitle="New Clients"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          /> */}
          <Typography variant="h5" sx={{ color: colors.grey[100] }}>
            Feedbacks
          </Typography>
          
          <Box 
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            >
            { feedback_statuss.map(t => 
              <Box key={t} >
                <Typography variant="h5" fontWeight="bold" sx={{ color: colors.grey[100] }}>
                  { feedbackCountByStatus[t] ?? 0}
                </Typography>
                <Typography variant="h6" sx={{ color: colors.greenAccent[400] }}>
                  { t }
                </Typography>
              </Box>
            ) }

          </Box>

          <Divider sx={{ margin: '5px 0' }}/>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            >
            { feedback_types.map(t => 
              <Box key={t} >
                <Typography variant="h5" fontWeight="bold" sx={{ color: colors.grey[100] }}>
                  { feedbackCountByType[t] ?? 0}
                </Typography>
                <Typography variant="h6" sx={{ color: colors.greenAccent[400] }}>
                  { t }
                </Typography>
              </Box>
            ) }
          </Box>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          gap={2}
          padding={2}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography variant="h5" sx={{ color: colors.grey[100] }}>
            Users
          </Typography>
          {/* <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          /> */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            { user_types.map(t => 
              <Box key={t} >
                <Typography variant="h4" fontWeight="bold" sx={{ color: colors.grey[100] }}>
                  { userCount[t] ?? 0}
                </Typography>
                <Typography variant="h5" sx={{ color: colors.greenAccent[400] }}>
                  { t }
                </Typography>
              </Box>
            ) }
          </Box>
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 7"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="15px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]} >
                Booking`s Count 
                <Typography variant="span" fontWeight="bold" fontSize={18} marginLeft={2} color={colors.greenAccent[500]}>
                  Total: { bookingCount }
                </Typography>
              </Typography>
            </Box>
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box marginTop={4} display={'flex'} gap={1}>
            {/* <LineChart isDashboard={true} /> */}
            {/* <BarChart height={320}
              barLabel="value"
              xAxis={ [{ data: booking_statuss, scaleType: 'band' }] }
              series={[
                { data: Object.values(bookingCountByStatus), label: 'By Status' },
              ]}
              /> */}


            <BarChart height={320}
              barLabel="value"
              xAxis={ [{ data: period_types, scaleType: 'band' }] }
              series={[
                { data: Object.values(bookingCountByPeriod), label: 'By Period Type' }
              ]}
              />

              
            <PieChart height={420}
              pieLabel="value"
              series={[
                { data: Object.keys(bookingCountByCheckIn).map(t => ({
                    id: t, label: t, value: bookingCountByCheckIn[t]
                  })),
                  arcLabel: p => p.value + ''
                },
              ]}
              slotProps={{
                legend: {
                  position: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }
              }}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Bookings
            </Typography>
            <Link color={colors.blueAccent[400]} sx={{ cursor: 'pointer' }} onClick={ e => navigate('/booking') }>MORE</Link>
          </Box>
          {bookings.map((row, i) => (
            <Box
              key={row.id }
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box display={'flex'} gap={4} alignItems={'center'}>
                {/* <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {row.id}
                </Typography> */}
                
                <Typography color={colors.grey[100]}>
                  {row.property.property_name}
                </Typography>

                <Typography color={colors.greenAccent[500]}>
                  {row.user?.username}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{ moment(row.booking_date).format('YYYY-MM-DD') }</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${ row.total_price }
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Properties Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
