import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, TextField, Box, Alert, useTheme, Button, Chip ,Pagination, Avatar, Divider, Dialog, Popover } from "@mui/material";
import { AddPhotoAlternate, ArrowCircleDownSharp, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { DataGrid } from "@mui/x-data-grid";
import Header from 'src/components/Header';
import moment from 'moment';
import useSelectFields from './useSelectFields';
import FormItem from '../properties/formItem';
import { asset, toGamel } from 'src/utils/Utils';
import { tokens } from "src/theme";
import useTopBarSearch from 'src/hooks/useTopBarSearch';
import { usePayouts, useFormSubmit } from 'src/services/payouts';
import ImagePreview from 'src/components/Uploader/ImagePreview';
import useToast from 'src/hooks/useToast';
import Confirm from "src/components/Confirm";
import PropertyImageCard from 'src/pages/properties/ImageCard';
import MyTable from 'src/components/MyTable';
import BookingDetail from '../booking/detail';
import { Calendar } from 'react-multi-date-picker';
import useTableColumnsByRole from 'src/hooks/useTableColumnsByRole';


export default function Payouts() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toast = useToast()
  
  const [previewImages, setPreviewImages] = useState([])
  const [currentBooking, setCurrentBooking] = useState(null)

  const [types, setTypes] = useState({
    payout_status: ''
  })
  
  const [payoutMonthAnchorEl, setPayoutMonthAnchorEl] = useState(null)
  const [payout_year_month, setPayoutYearMonth] = useState('');

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })
  
  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    setSearchKeyword(keyword?.trim() || '')
  })

  const { paginate, setPaginate, isLoading, error, data = { rows: [], count: 0 }, refetch } = usePayouts({
    ...types,
    payout_year_month,
    searchKeyword
  })

  if (error) {
    toast(error?.message)
  }

  // console.log(data)
  const { rows, count } = data

  useEffect(() => {
    if (!isLoading && count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  const mutation = useFormSubmit()

  const [replyAnswer, setReplyAnswer] = useState('');
  const handleRepley = ({ row, index, column }) => {
    Confirm.open({
      title: <p>
        Reply for Payout <Typography variant='subtitle1'>{ row.enquiry }</Typography>
      </p>,
      content: <Box>
        <TextField color='success' defaultValue={row.answer} label={ row.answer ? "Update answer" : "" } sx={{width: '300px', fontSize: '18px'}} multiline rows={4} autoFocus 
          onChange={ e => setReplyAnswer(_ => e.target.value) }
          />
      </Box>, 
      confirmText: 'Reply',
      confirmColor: 'success',
      ConfirmIcon: <EditOutlined />,
      onCancel: _ => Confirm.close(),
      onConfirm: e => {
        console.log(replyAnswer)
        if (!replyAnswer.trim()) {
          toast('Invalid Answer')
        } else {
          mutation.mutate({ id: row.id, answer: replyAnswer }, { onSuccess: _ => refetch() })
          Confirm.close()
        }
      },
    })
  }

  const columns = useTableColumnsByRole([
    { field: "id", headerName: "ID", with: 40 },
    { field: "user", headerName: "User", width: 140, cellClassName: 'content-wrap',
      renderCell: ({ row: { user } }) => 
        user 
          ? <>
              <Box>{user.username}</Box>
              <Box>Role: {user.role}</Box>
              <Box>Mobile No:{user.phone}</Box>
              <Box style={{ color: '#4fc1ff' }}>Email: {user.email}</Box>
            </>
          : '-'
      ,
    },
    { field: "property", headerName: "Property", flex: 3, cellClassName: 'content-wrap',
      renderCell: ({ row: { property } }) => 
        property 
          ? <PropertyImageCard width="80px" imageHeight="40px" previewImage property={property}/>
          : '-'
      ,
    },
    { field: "payout_year_month", headerName: toGamel('payout_year_month'), flex: 2, cellClassName: 'content-wrap' },
    { field: "currency", headerName: 'Currency', flex: 2, cellClassName: 'content-wrap' },
    { field: "total_payout", headerName: toGamel('total_payout'), flex: 2, cellClassName: 'content-wrap' },
    { field: "maintenance_fee", headerName: toGamel('maintenance_fee'), flex: 2, cellClassName: 'content-wrap' },
    { field: "other_fee", headerName: toGamel('other_fee'), flex: 2, cellClassName: 'content-wrap' },
    { field: "service_fee", headerName: toGamel('service_fee'), flex: 2, cellClassName: 'content-wrap' },
    { field: "water_bill", headerName: toGamel('water_bill'), flex: 2, cellClassName: 'content-wrap' },
    { field: "payout_status", headerName: toGamel('status'), width: 120, cellClassName: 'content-wrap',
      renderCell: ({ row: { payout_status } }) => {
        // 'RESERVED','PAYMENT_PENDING','CONFIRMED','CANCELLED','PAYMENT_FAILED'
        const icon = { DUE: '⏳', PAID: "✅" }[payout_status]
        return (
          payout_status
            ? <Chip title={payout_status} variant="outlined" color={ 'warning' } size="small" label={ payout_status + ' ' + icon }/>
            : '-'
        );
      },
    },
    { field: "date", headerName: "CreatedAt", width: 120, cellClassName: 'content-wrap',
      renderCell: ({ row }) => <>
        <Box>{ moment(row.created_at).format('YYYY-MM-DD') } </Box>
      </>
    },
    
    { field: "actions", headerName: "Actions", width: 90, cellClassName: 'content-wrap',
      renderCell: ({ row }) => <Link to={'/payout-form?id=' + row.id }>
        <Chip color="info" icon={<EditOutlined/>} size="small" variant="outlined"label="Edit" />
      </Link>
    },
  ])

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          sx={{ flex: 1, marginBottom: 0 }}
          title="Payouts"
          subtitle="List of Payouts"
          />
        
        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'} alignItems={'center'}>
          
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '150px' }} name={t.field} onChange={t.onChange}/>
          )}

          <Button sx={{ border: `1px solid ${colors.grey[500]}`, color: colors.greenAccent[400], fontWeight: 'bold' }} variant='outlined' 
            onClick={e => setPayoutMonthAnchorEl(e.currentTarget) }
            >
            { payout_year_month || 'Month' }
            { !!payout_year_month && <DeleteOutline color='secondary' sx={{marginLeft: 1}} onClick={e => { e.stopPropagation();setPayoutYearMonth('') }}/> }
          </Button>
          
          <Popover
            anchorEl={ payoutMonthAnchorEl }
            open={ Boolean(payoutMonthAnchorEl) }
            onClose={ e => setPayoutMonthAnchorEl(null) }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            >
            <Calendar
              format="YYYY-MM"
              onlyMonthPicker
              onChange={ e => { setPayoutYearMonth('2024-05');setPayoutMonthAnchorEl(null) } }
              className={ ['purple', theme.palette.mode == 'dark' && 'bg-dark'].join(' ') }
              />
          </Popover>
        </Box>

        {/* <Link to={'/payout-form'} style={{ textDecoration: "none" }}>
          <Button color="success" variant="contained" sx={{ marginRight: "12px" }} >
            Create a Payout
          </Button>
        </Link> */}
      </Box>
      
      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        rowHeight={80}
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      
      { previewImages.length ? <ImagePreview files={previewImages} onClose={ e => setPreviewImages([]) } currentIndex={0}/> : '' }

      <Dialog open={ !!currentBooking } onClose={ e => setCurrentBooking(null) }>
        <Box padding={2}>
          { !!currentBooking && <BookingDetail booking={ currentBooking }/> }
        </Box>
      </Dialog>
    </Box>
  );
}