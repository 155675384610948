import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, TextField, Box, Alert, useTheme, Button, Chip ,Pagination } from "@mui/material";
import Header from 'src/components/Header';
import ButtonRadios from "src/components/ButtonRadios";
import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'src/utils/axiosInstance';
import moment from 'moment';
import useSelectFields from './useSelectFields';
import FormItem from '../properties/formItem';
import { asset, toGamel } from 'src/utils/Utils';
import { tokens } from "src/theme";
import useTopBarSearch from 'src/hooks/useTopBarSearch';
import useHasAccessByRole from 'src/hooks/useHasAccessByRole';

// const mockData = [
//   { id: 1, images: [{ image_data: '../../assets/cat.jpg' }], created_at: '2024-05-01T08:00:00.999', type: 'DISCOUNT', title: 'Lizard', desc: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica' }
// ]

export default function News() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const hasAccess = useHasAccessByRole()
  
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    pageCount: 1
  })
  const [message, setMessage] = useState(null);

  const [types, setTypes] = useState({ 
    news_type: '',
    news_status: '',
    audience_type: ''
  })

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })
  

  // console.log(typesFields)

  // const newsTypes = ['-', 'DISCOUNT', 'EVENTS', 'OTHER']
  // const [newsType, setNewsType] = useState('-')
  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    // console.log(keyword)
    setSearchKeyword(keyword?.trim() || '')
  })

  const {isLoading, error, data = { rows: [], count: 0 } } = useQuery({
    queryKey: ['news', types.news_type, types.news_status, types.audience_type, paginate.page, searchKeyword],
    queryFn: () => {
      const { page, pageSize } = paginate;
      const useTypes = {}
      Object.keys(types).forEach(k => {
        if (types[k]) {
          useTypes[k] = types[k]
        }
      })
      const useKeyword = searchKeyword ? { keyword: searchKeyword } : {};
      return axiosInstance.get('/api/newsFeeds', { params: { ...useTypes, ...useKeyword, page, pageSize } })
    }
  })

  // console.log(data)
  const { rows, count } = data

  useEffect(() => {
    if (!isLoading && count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  useEffect(() => {
    if (error) {
      setMessage({ state: 'error', text: error.message || 'Load properties failed !' })
    }
  }, [error])

  return (
  <Box m="20px">
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
      <Header
        sx={{ flex: 1, marginBottom: 0 }}
        title="News"
        subtitle="List of News for Future Reference"
        />
      
      <Box display={'flex'} gap={2} marginRight={2} flexWrap={'wrap'}>
        { Object.values(types).filter(Boolean).length > 0 && 
          <Button color="error" variant="outlined" onClick={ e => setTypes({}) }>Clear Filters ❌</Button> 
        }
        { typesFields.map(t => 
          <FormItem key={t.field} size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '140px' }} name={t.field} onChange={t.onChange}/>
        )}
      </Box>

      { hasAccess && 
        <Link to={'/news-form'} state={ (types['news_type'] ? { news_type: types['news_type'] } : {}) } 
          style={{ textDecoration: "none" }}>
          <Button color="success" variant="contained" sx={{ marginRight: "12px" }} >
            Create News
          </Button>
        </Link>
      }
    </Box>

    <Box gap={4} display={'flex'} flexWrap={'wrap'} height={'74vh'} overflow={'auto'} marginTop={2}>
      
      {message?.text && (
        <div className="mb-2 w-8/12">
          <Alert variant="filled" severity={ message.state || "error" }>
            {message.text}
          </Alert>
        </div>
      )}

      { rows.map(item => (
        <Link key={item.id}  to={ "/news-form?id=" + item.id } style={{ textDecoration: "none" }}>
          <Card sx={{ maxWidth: 240 }}>
            <CardActionArea sx={{ backgroundColor: colors.grey[700] }}>
              <CardMedia
                component="img"
                sx={{ height: "160px" }}
                image={ asset(item.images[0]?.image_data, 'base64') || asset(item.images[0]?.image_url) }
                alt="green iguana"
                />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  { item.title } 
                  <Chip sx={{ marginLeft: '10px' }} variant='outlined' size='small' color='success' label={ item.news_status } title={toGamel('news_status')}/>
                </Typography>

                <Typography variant="body2" className='text-ellipsis' color="text.secondary">
                  { item.short_description }
                </Typography>

                <Typography variant="p" component={ Box } display={'flex'} alignItems={'center'} flexWrap={'wrap'} mt={1} gap={2} justifyContent={'space-between'}>
                  <Button size="small" variant='outlined' color='warning' title={toGamel('news_type')}>{item.news_type}</Button> 
                  <Button size="small" variant='outlined' color='error' title={toGamel('audience_type')}>{item.audience_type}</Button> 
                  <Box color={ colors.blueAccent[200] } fontSize={13} title={ toGamel('created_at') } >{ moment(item.created_at).format('YYYY/M/D HH:mm') }</Box>
                  { item.published_at &&  <Box color={ colors.greenAccent[200] } fontSize={13} title={ toGamel('published_at') } >{ moment(item.published_at).format('YYYY/M/D HH:mm') }</Box> }
                </Typography>

              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      ))
      }
      { !rows.length && !isLoading &&
        <Box padding={10} color={colors.grey[300]}>no rows</Box>
      }
    </Box>

    <Box gap={2} mt={2} width="90%" display={'flex'} p={1.5} alignItems={'center'} justifyContent="flex-end" borderRadius={"0 0 4px 4px"}
      >
      <Pagination size={'small'} color="info"
        count={ paginate.pageCount } 
        total={paginate.total} page={paginate.page} 
        onChange={ (e, page) => setPaginate({ ...paginate, page }) }
        />
      <span>Total: { paginate.total }</span>
    </Box>
  </Box>
  );
}