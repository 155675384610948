import { useIsFetching, useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import axiosInstance from "src/utils/axiosInstance";

const url = '/api/feedbacks';

export function DeleteFeedback(id) {
  if (!+id) return Promise.reject({ message: 'missing field: id' })
  return axiosInstance.delete(`${url}/${id}`)
}

export function useFeedbacks({ type, status, searchKeyword }) {
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    pageCount: 1
  })

  const query = useQuery({
    queryKey: ['feedbacks', type, status, paginate.page, searchKeyword],
    queryFn: () => {
      const { page, pageSize } = paginate;
      const useTypes = {}
      if (status) {
        useTypes.status = status
      }
      if (type) {
        useTypes.type = type
      }
      const useKeyword = searchKeyword ? { keyword: searchKeyword } : {};
      return axiosInstance.get(url, { params: { ...useTypes, ...useKeyword, page, pageSize } })
    }
  })

  return {
    ...query,
    paginate,
    setPaginate,
  }
}

export function useCount({ status, type }) {
  const query = useQuery({
    queryKey: [`feedbacks_count`, status, type],
    queryFn: _ => {
      return axiosInstance.get('/api/feedbacks/count', {
        params: {
          ...(status ? { status } : {}),
          ...(type ? { type } : {}),
        }
      })
    },
  })

  return query
}

export function useFormSubmit({ onSuccess, onError }) {
  const mutation = useMutation({
    mutationFn: ({ id, ...formData }) => {
      if (id) {
        return axiosInstance.put(url + '/' + id, formData )
      }
      return axiosInstance.post(url, formData)
    }
  })

  useEffect(() => {
    if (mutation.isError) {
      onError()
    }
    if (mutation.isSuccess) {
      onSuccess()
    }
  }, [mutation.isError, mutation.isSuccess])

  return mutation
}