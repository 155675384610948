import {
  SET_GLOBAL_VARIABLE
} from './actionTypes';

const initialState = {
  user: null,
  user_types: ['DEVELOPER','OWNER','AGENT','TENANT'],
  zone_types: ['HIGH','MEDIUM','LOW'],
  view_types: ['KLCC','TRX','Golf View','City View','No View'],
  furnish_types: ['FULLY_FURNISHED','HALF_FURNISHED','NOT_FURNISHED'],
  room_types: ['STUDIO','1+1','2 rooms standard','2 rooms luxary','3 rooms']
};

const GlobalVariable = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_VARIABLE:
      state = {
        ...state,
        [action.payload.key]: action.payload.value
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GlobalVariable;
