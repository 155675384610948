import { useIsFetching, useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import useToast from "src/hooks/useToast";
import axiosInstance from "src/utils/axiosInstance";

const url = '/api/electricUsages';

export function useElectricUsages({ property_id, dateStart, dateEnd }) {

  const query = useQuery({
    queryKey: ['electric_usages', property_id, dateStart, dateEnd],
    queryFn: () => {
      return axiosInstance.get(url, { params: { property_id, dateStart, dateEnd } })
    }
  })

  return query
}