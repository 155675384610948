import { useEffect, useState } from 'react';
import { Typography, TextField, Box, Alert, useTheme, Button, Chip ,Pagination, Avatar, Divider } from "@mui/material";
import { AddPhotoAlternate, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { DataGrid } from "@mui/x-data-grid";
import Header from 'src/components/Header';
import moment from 'moment';
import useSelectFields from './useSelectFields';
import FormItem from '../properties/formItem';
import { asset, toGamel } from 'src/utils/Utils';
import { tokens } from "src/theme";
import useTopBarSearch from 'src/hooks/useTopBarSearch';
import { useFeedbacks, DeleteFeedback, useFormSubmit } from 'src/services/feedbacks';
import ImagePreview from 'src/components/Uploader/ImagePreview';
import useToast from 'src/hooks/useToast';
import Confirm from "src/components/Confirm";
// import PropertyImageCard from 'src/pages/properties/ImageCard';
import MyTable from 'src/components/MyTable';
import useTableColumnsByRole from 'src/hooks/useTableColumnsByRole';


export default function Feedbacks() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toast = useToast()
  
  const [previewImages, setPreviewImages] = useState([])

  const [types, setTypes] = useState({
    type: '',
    status: ''
  })

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })
  
  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    setSearchKeyword(keyword?.trim() || '')
  })

  const { paginate, setPaginate, isLoading, error, data = { rows: [], count: 0 }, refetch } = useFeedbacks({
    ...types,
    searchKeyword
  })

  if (error) {
    toast(error.message)
  }

  // console.log(data)
  const { rows, count } = data

  useEffect(() => {
    if (!isLoading && count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  const mutation = useFormSubmit({
    onSuccess: _ => {
      toast('Success !', 1)
      refetch()
    },
    onError: _ => toast('Delete Failed !')
  })

  // const [replyAnswer, setReplyAnswer] = useState('');
  const handleRepley = ({ row, index, column }) => {
    let replyAnswer = row.answer || ''
    Confirm.open({
      title: <p>
        Reply for Feedback <Typography variant='subtitle1'>{ row.enquiry }</Typography>
      </p>,
      content: <Box>
        <TextField color='success' defaultValue={row.answer} label={ row.answer ? "Update answer" : "" } sx={{width: '300px', fontSize: '18px'}} multiline rows={4} autoFocus 
          onChange={ e => replyAnswer = e.target.value }
          />
      </Box>, 
      confirmText: 'Reply',
      confirmColor: 'success',
      ConfirmIcon: <EditOutlined />,
      onCancel: _ => Confirm.close(),
      onConfirm: e => {
        console.log(replyAnswer)
        if (!replyAnswer.trim()) {
          toast('Invalid Answer')
        } else {
          mutation.mutate({ id: row.id, answer: replyAnswer })
          refetch()
          Confirm.close()
        }
      },
    })
  }

  const handleRemove = ({ row, index, column }) => {
    Confirm.open({
      title: <h1 style={{ color: colors.red[400],fontWeight: "bold", fontSize: '20px' }}>Confirm to remove this Feedback.</h1>,
      content: <Box sx={{ width: '300px', fontSize: '20px' }}>
        <p>{row.id} | { row.owner?.username } | ...</p>
      </Box>, 
      confirmText: 'Delete',
      onCancel: _ => Confirm.close(),
      onConfirm: async e => {
        try {
          await DeleteFeedback(row.id)
          toast('Delete Success !', 1)
        } catch (e) {
          toast('Delete Failed !')
        }
        refetch()
        Confirm.close()
      },
      ConfirmIcon: <DeleteOutline />
    })
  }

  const columns = useTableColumnsByRole([
    { field: "id", headerName: "ID", with: 40 },
    { field: "user", headerName: "User", width: 140, cellClassName: 'content-wrap',
      renderCell: ({ row: { user } }) => 
        user 
          ? <>
              <Box>{user.username}</Box>
              <Box>Role: {user.role}</Box>
              <Box>Mobile No:{user.phone}</Box>
              <Box style={{ color: '#4fc1ff' }}>Email: {user.email}</Box>
            </>
          : '-'
      ,
    },
    // { field: "property", headerName: "Property", flex: 3, cellClassName: 'content-wrap',
    //   renderCell: ({ row: { property } }) => 
    //     property 
    //       ? <PropertyImageCard width="80px" imageHeight="40px" previewImage property={property}/>
    //       : ''
    //   ,
    // },
    { field: "enquiry", headerName: 'Enquiry', flex: 2, cellClassName: 'content-wrap' },
    { field: "image", headerName: "Image", flex: 1, cellClassName: 'content-wrap',
      renderCell: ({ row }) => {
        const url = row.image?.image_data
          ? asset(row.image.image_data, 'base64')
          : asset(row.image?.image_url);
        return row.image 
        ? <Avatar variant='square' src={ url } style={{ cursor: 'pointer' }}
            onClick={ e => setPreviewImages([{ url }]) }
          > <AddPhotoAlternate/> </Avatar>
        : '-'
      }
    },
    { field: "answer", headerName: 'Answer', flex: 2, cellClassName: 'content-wrap', },
    // { field: "gender", headerName: "Gender", flex: 1, 
    //   renderCell: ({row}) => row.gender || <span title="unset">🙅🏼‍♀️</span>
    // },
    
    { field: "type", headerName: 'Type', flex: 1.5, 
      renderCell: ({ row: { type } }) => {
        const icon = { DEFECT: '💔', MANAGEMENT: '🔧', OTHER: "" }[type]
        return <Box>
          { type 
            ? <Chip title={type} variant="outlined" color={ 'info' } size="small" label={ type + ' ' + icon }/> 
            : ''
          }
        </Box>
      },
    },
    { field: "status", headerName: toGamel('status'), width: 120, cellClassName: 'content-wrap',
      renderCell: ({ row: { status } }) => {
        // 'RESERVED','PAYMENT_PENDING','CONFIRMED','CANCELLED','PAYMENT_FAILED'
        const icon = { PENDING: '⏳', RESOLVED: "✅", REPLIED: "✔" }[status]
        return (
          status 
            ? <Chip title={status} variant="outlined" color={ 'warning' } size="small" label={ status + ' ' + icon }/>
            : '-'
        );
      },
    },
    { field: "date", headerName: "CreatedAt/RepliedAt", flex: 2,  width: '120px', cellClassName: 'content-wrap',
      renderCell: ({ row }) => <>
        <Box>{ moment(row.created_at).format('YYYY-MM-DD HH:mm') } </Box>
        { row.replied_at && <Box>{ moment(row.replied_at).format('YYYY-MM-DD HH:mm') }</Box> }
      </>
    },
    {
      field: "actions", headerName: "Actions", flex: 1.5,
      renderCell: ({ row, index, column }) => (
        <Box lineHeight={1.4} display={'flex'} flexDirection={'column'} justifyContent={'center'} height="100%" gap={'10px'} sx={{ '& .pointer': { cursor: 'pointer' } }}>
          { !['RESOLVED'].includes(row.status) && 
            <Chip icon={<EditOutlined />} variant="outlined" color="success" label="Reply" size="small" className="pointer" onClick={e => handleRepley({ row, index, column }) }/> 
          }
          <Chip icon={<DeleteOutline />} variant="outlined" color="error" label="Del" size="small" className="pointer" onClick={e => handleRemove({ row, index, column }) }/>
        </Box>
      ),
    },
  ])

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          sx={{ flex: 1, marginBottom: 0 }}
          title="Feedbacks"
          subtitle="List of Feedbacks"
          />
        
        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'}>
          { Object.values(types).filter(Boolean).length > 0 && 
            <Button color="error" variant="outlined" onClick={ e => setTypes({}) }>Clear Filters ❌</Button> 
          }
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '140px' }} name={t.field} onChange={t.onChange}/>
          )}
        </Box>
      </Box>
      
      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        rowHeight={95}
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      
      { previewImages.length ? <ImagePreview files={previewImages} onClose={ e => setPreviewImages([]) } currentIndex={0}/> : '' }
    </Box>
  );
}