import { Box, Typography, useTheme, Pagination, Button, Chip, Menu, MenuItem, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "src/utils/axiosInstance";
import { useEffect, useState } from "react";
import useTopBarSearch from "src/hooks/useTopBarSearch";
import MyTable from "src/components/MyTable"
import useSelectFields from "./useSelectFields";
import { useFormSubmit, useUsers } from "src/services/users";
import FormItem from "../properties/formItem";
import useToast from "src/hooks/useToast";
import useHasAccessByRole from "src/hooks/useHasAccessByRole";

const Owner = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toast = useToast()

  const hasAccess = useHasAccessByRole();

  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    // console.log(keyword)
    setSearchKeyword(keyword?.trim() || '')
  })

  const [types, setTypes] = useState({
    user_type: '', role: '', gender: ''
  })

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      const { name, value } = e.target
      setTypes(prev => ({...prev, [name]: value }))
    } 
  })

  const {isLoading, error, data, paginate, setPaginate, refetch } = useUsers({...types, searchKeyword})

  const { rows, count } = data || { rows: [], count: 0 };

  useEffect(() => {
    if (count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  const [userTypeAnchorEl, setUserTypeAnchorEl] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "username", headerName: "Name", flex: 2, cellClassName: "name-column--cell", },
    { field: "gender", headerName: "Gender", flex: 1, 
      valueFormatter: v => v || '🙅🏼‍♀️'
    },
    { field: "phone", headerName: "Phone Number", flex: 2 },
    { field: "email", headerName: "Email", flex: 2,
      renderCell: ({ row: { email } }) => <Box style={{ color: '#4fc1ff' }}>{email}</Box>
    },
    { field: "user_type", headerName: "Type", flex: 1,
      renderCell: ({ row: { user_type, id } }) => {
        const color = { DEVELOPER: 'info', OWNER: 'success', AFENT: 'primary', TENANT: 'default' }[user_type]
        return (
          <Box>
            <Chip data-user_type={user_type} data-user_id={id} sx={{cursor: 'pointer', '&:hover': { border: `1px solid ${colors.red[400]}` } }} 
              onClick={ e => hasAccess ? setUserTypeAnchorEl(e.currentTarget) : null } title={user_type} variant="outlined" color={ color } size="small" label={ user_type }/>
          </Box>
        );
      },
    },
    // { field: "user_status", headerName: "Status", flex: 1, 
    //   renderCell: ({ row: { user_status } }) => {
    //     const icon = { VERIFIED: "✅", NOT_VERIFIED: "📧", NOT_ACTIVE: "📵" }[user_status]
    //     return (
    //       <Chip title={user_status} variant="outlined" color={ 'info' } size="small" label={ icon }/>
    //     );
    //   },
    // },

    { field: "role", headerName: "Role", flex: 2,
      renderCell: ({ row: { role } }) => {
        const icon = { ADMIN: <AdminPanelSettingsOutlinedIcon color="error"/>, USER: <LockOpenOutlinedIcon /> }[role]
        const color = { ADMIN: 'warning', role: 'info' }[role]
        return (
          <Chip title={role} color={ color } size="small" label={ role } icon={ icon }/>
        );
      },
    },
  ];

  const mutation = useFormSubmit({
    onSuccess() {
      toast('Success', 1)
      refetch()
    },
    onError(e) {
      toast('Failed', -1)
    }
  })

  const handleUserTypeUpdate = t => {
    const { user_id } = userTypeAnchorEl?.dataset || {}
    if (!+user_id) {
      return toast('invalid user id');
    }
    mutation.mutate({ id: +user_id, user_type: t })

    setUserTypeAnchorEl(null)
  }

  const prefix = 'user-type'

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          sx={{ flex: 1, marginBottom: 0 }}
          title="USERS"
          subtitle="Managing the Users"
          />
        
        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'}>
          { Object.values(types).filter(Boolean).length > 0 && 
            <Button color="error" variant="outlined" onClick={ e => setTypes({}) }>Clear Filters ❌</Button> 
          }
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '140px' }} name={t.field} 
              onChange={t.onChange}
              />
          )}
        </Box>
      </Box>
      
      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      <Menu component="nav"
        id={ `${prefix}-menu` }
        MenuListProps={{
          'aria-labelledby': `${prefix}-button`,
        }}
        anchorEl={userTypeAnchorEl} 
        open={ !!userTypeAnchorEl }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={ e => setUserTypeAnchorEl(null) }
        >
        { !!userTypeAnchorEl && 
          <Typography variant="h5" sx={{ padding: 1 }} color={colors.blueAccent[200]} fontWeight={'bold'}>
            Update User's Type
          </Typography> 
        }
        { typesFields.find(t => t.field === 'user_type').options.map((t, i) => 
          <Box key={i} padding={1} sx={{ '& .MuiMenuItem-root.Mui-selected': { backgroundColor: colors.grey[400] } }}>
            { i > 0 && <Divider/> }

            <MenuItem key={t.name} 
              selected={ t.value === userTypeAnchorEl?.dataset?.user_type }
              onClick={e => handleUserTypeUpdate(t.value) }
              >
              { t.value }
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default Owner;
