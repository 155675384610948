
export function isGoogleMapScriptLoaded(id) {
	const scripts = document.head.getElementsByTagName('script');
	for (let i = 0; i < scripts.length; i++) {
		if (scripts[i].getAttribute('id') === id) {
			return scripts[i];
		}
	}

	return false;
}

/**
 * @param {string} apiKey 
 * @param {object} options
 * @returns {promise} loaded
 */
export function loadScript(apiKey, options = { 
  v: "weekly",
  libraries: "places",
}) {
  if (!apiKey) {
    return Promise.reject({ message: 'Google maps error: apiKey missing' })
  }
  // (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
  //   key: apiKey,
  //   ...options
  // });
  const id = 'google-script';
  const dom = isGoogleMapScriptLoaded(id)
	if (dom) {
		// Make sure the script is loaded
    if (window.google?.maps) {
      return Promise.resolve()
    }
		return new Promise(resolve => dom.addEventListener('load', resolve));
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=${options.v}&libraries=${options.libraries}`;
	document.querySelector('head').appendChild(script);

  // const styleLink = document.createElement('link');
  // styleLink.setAttribute('href', 'https://fonts.googleapis.com/css?family=Roboto:400,500');
  // styleLink.setAttribute('rel', 'stylesheet');
  // document.querySelector('head').appendChild(styleLink);

	return new Promise((resolve, reject) => {
		script.onload = resolve
    script.onerror = reject({ message: 'Load script file failed' })
	});
  // return Promise.resolve()
}

/**
 * 
 * @param {object} location
 * @param {number} location.lat 
 * @param {number} location.lng 
 * @returns {boolean}
 */
export function isValidLocation(location) {
	return location && Math.abs(location.lat) <= 90 && Math.abs(location.lng) <= 180;
}

export function getCurrentLocation() {
	if (navigator.geolocation) {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					resolve(pos)
				},
				(err) => {
					console.log(err)
					reject('[getCurrentLocation] error: fail!')
				}
			);
		})
	}
	return Promise.reject('[getCurrentLocation] error: Browser version is too lower!')
}