import { useIsFetching, useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import useToast from "src/hooks/useToast";
import axiosInstance from "src/utils/axiosInstance";

const url = '/api/payouts';

export function GetPayouts(params = {}) {
  return axiosInstance.get(url, {
    params
  })
}

export function usePayouts({ payout_status, payout_year_month = '' }) {
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    pageCount: 1
  })

  const query = useQuery({
    queryKey: ['payouts', payout_status, payout_year_month, paginate.page],
    queryFn: () => {
      const { page, pageSize } = paginate;
      const useTypes = {}
      if (payout_status) {
        useTypes.payout_status = payout_status
      }
      if (payout_year_month) {
        useTypes.payout_year_month = payout_year_month
      }
      const useKeyword = {}//searchKeyword ? { keyword: searchKeyword } : {};
      return axiosInstance.get(url, { params: { ...useTypes, ...useKeyword, page, pageSize } })
    }
  })

  return {
    ...query,
    paginate,
    setPaginate,
  }
}

export function usePayout({ id }, options = {}) {
  const query = useQuery({
    queryKey: [`payout`, id],
    queryFn: _ => {
      return axiosInstance.get(url + '/' + id)
    },
    enabled: !!+id,
    ...options
  })

  return query
}


export function useCount({ payout_year_month, payout_status }) {
  const query = useQuery({
    queryKey: [`payouts_count`, payout_year_month, payout_status],
    queryFn: _ => {
      return axiosInstance.get('/api/payouts/count', {
        params: {
          ...(payout_year_month ? { payout_year_month } : {}),
          ...(payout_status ? { payout_status } : {}),
        }
      })
    },
  })

  return query
}

export function useSum(field, { payout_year_month, payout_status }) {
  const query = useQuery({
    queryKey: [`payouts_sum`, field, payout_year_month, payout_status],
    queryFn: _ => {
      return axiosInstance.get('/api/payouts/sum', {
        params: {
          field,
          ...(payout_year_month ? { payout_year_month } : {}),
          ...(payout_status ? { payout_status } : {}),
        }
      })
    },
  })

  return query
}



export function useFormSubmit(options) {
  const mutation = useMutation({
    mutationFn: ({ id, ...formData }) => {
      if (id) {
        return axiosInstance.put(url + '/' + id, formData )
      }
      return axiosInstance.post(url, formData)
    }
  })

  const toast = useToast()
  let onSuccess = e => toast('success', 1)
  let onError = e => toast(mutation.error?.message || 'request failed')

  if (options) {
    onSuccess = options.onSuccess || (e => null)
    onError = options.onError || (e => null)
  }

  useEffect(() => {
    if (mutation.isError) {
      onError()
    }
    if (mutation.isSuccess) {
      onSuccess()
    }
  }, [mutation.isError, mutation.isSuccess])

  return mutation
}