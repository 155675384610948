
import { useEffect, useState } from "react";

import { delay, toGamel } from "src/utils/Utils";

import axiosInstance from "src/utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";


export default function useSelectFields({ clearable = false, onFormChange }) {

  const fields = [
    'furnish_type', 'zone_type', 'view_type', 'room_type', 
    // 'number_of_parking', 'number_of_toilets', 'number_of_rooms',
    'owner_id', 'project_id'
  ];

  const [selectFields, setSelectFields] = useState(
    fields.map(field => 
      ({
        type: 'select',
        field,
        required: true,
        label: toGamel(field),
        onChange: onFormChange,
        options: []
      })
    )
  )

  const { isLoading, data = [] } = useQuery({
    queryKey: ['properity_select_types'], 
    queryFn: _ => Promise.all([
      axiosInstance.get('/api/projects'),
      axiosInstance.get('/api/users?user_type=OWNER'),
      ...fields.slice(0, 4).map(f => axiosInstance.get(`/api/properties/${f}`)),
    ])
  })

  useEffect(() => {
    if (!isLoading) {
      const [projects, owners, ...results] = data
      selectFields.forEach(t => {
        // if (t.field === 'number_of_parking' || t.field === 'number_of_toilets') { // 停车场 || 厕所?
        //   t.options = [1,2,3,4,5,6,7,8,9,10].map(p => ({ name: p, value: p }))
        // }
        if (t.field === 'owner_id') {
          t.options = owners.map(t => ({ 
            value: t.id,
            name: <div  style={{ lineHeight: 1.1 }}>
              <span>{t.id}. {t.username} </span> <br/>
              <span style={{color: '#4fc1ff' }}>{t.email}</span>
            </div>,
            label: t.id + '. ' + t.username, 
          }))
        }
        if (t.field === 'project_id') {
          t.options = projects.map(t => ({ name: t.id + '. ' + t.name, value: t.id }))
        }
        fields.slice(0, 4).forEach((k, i) => {
          if (t.field === k) {
            t.options = results[i].map(t => ({ name: toGamel(t), value: t }))
          }
        })
        if (clearable) {
          t.options.push({ name: '❌UNSET❌', value: '' })
        }
      })
      // console.log(selectFields)
      setSelectFields([...selectFields])
    }
  }, [isLoading])

  return selectFields
}