import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  useTheme,
  Alert,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { tokens } from "../../theme";

import { getCurrentLocation, isValidLocation, loadScript } from './utils';
import { delay } from "src/utils/Utils";

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const center = { lat: 3.1390, lng: 101.6869 }; // 马来西亚中心点
// const bounds = { // 马来西亚边界点
//   north: 7.36,
//   south: 1.20,
//   west: 99.64,
//   east: 119.27,
// }
const GMapPicker = ({ 
  defaultLocation = center, searchPlaceholder = 'Search by keyword', zoom = 16, styles = {}, className,
  hideSearch = false,
  withAddress = false,
  onLocationChange = _ => null, 
  onZoomChange = _ => null
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const mapView = useRef(MAP_VIEW_ID);
  const MAP_VIEW_ID = 'google-map-view-' + apiKey;
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [message, setMessage] = useState(null);

  const initMap = useCallback(async () => {
    try {
      await loadScript(apiKey)
    } catch (e) {
      console.log(e)
      setMessage(e?.message || 'Load map components failed.')
      await delay(2000);
      setMessage(_ => '')
      return;
    }
    // const position = !isValidLocation(defaultLocation)
    //   ? await getCurrentLocation().catch(e => null)
    //   : defaultLocation;
    const position = isValidLocation(defaultLocation) ? defaultLocation : center;

    // if (!position) throw 'init position failed';

    const res = loadMap({
      MAP_VIEW_ID: MAP_VIEW_ID,
      center: position,
      zoom,
      handleLocationChange,
      handleZoomChange
    })
    setMap(res.map)
    setMarker(res.marker)
  }, [apiKey])

  useEffect(() => {
    initMap();
  }, [apiKey]);

  const handleLocationChange = position => {
    // const currentLocation = marker.current.getPosition();
    // console.log('handleLocationChange', currentLocation)//, marker.current, marker.current.getPlace())
    // const position = {
    //   lat: currentLocation.lat(),
    //   lng: currentLocation.lng()
    // }
    if (withAddress) {
      geocodeLatLng(position)
        .then(res => {
          const infoWindow = new window.google.maps.InfoWindow();
          infoWindow.setContent(res.formatted_address);
          infoWindow.open(map.current, marker.current);
          onLocationChange({ ...position, address: res.formatted_address })
        })
        .catch(e => {
          onLocationChange({ ...position, address: false })
        });
    } else {
      onLocationChange(position)
    }
  }
  const handleZoomChange = e => {
    onZoomChange(e)
  }
  
  return <>
    <Box
      className="flex-1"
      style={{ backgroundColor: '#fff', borderRadius: '4px' }}
      >
      {message && (
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      )}

      { !hideSearch && <input id={ MAP_VIEW_ID + '-searchbox' } autoComplete="off"
          className="w-full"
          style={{
            fontSize: 16,
            borderRadius: 4,
            backgroundColor: 'transparent',
            color: colors.grey[900],
            marginBottom: 0,
            padding: '4px 12px',
            outline: 'none'
          }}
          placeholder={ searchPlaceholder }
        />
      }
      <div
        id={ MAP_VIEW_ID }
        style={{ width: '100%', height: '40vh', ...styles }}
        className={ className }
      />
    </Box>
  </>
}

function loadMap({ 
  MAP_VIEW_ID,
  center = { lag: 0, lng: 0 }, 
  componentRestrictions = { country: 'my' },
  zoom = 17, 
  handleLocationChange = _ => null, 
  handleZoomChange = _ => null
}) {
  const Google = window.google;
  const map = {}, marker = {};
  map.current = new Google.maps.Map(document.getElementById(MAP_VIEW_ID), {
    center: center,
    zoom,
    mapTypeControl: true,
    mapTypeId: 'roadmap'
  });

  if (!marker.current) {
    marker.current = new (Google.maps.marker?.AdvancedMarkerElement || Google.maps.Marker)({
      position: center,
      map: map.current,
      draggable: true
    });
    Google.maps.event.addListener(marker.current, 'dragend', event => {
      const dragedLocation = event.latLng;
      handleLocationChange({
        lat: dragedLocation.lat(),
        lng: dragedLocation.lng()
      })
    });
  } else {
    marker.current.setPosition(center);
  }

  map.current.addListener('click', event => {
    const clickedLocation = event.latLng;

    marker.current.setPosition(clickedLocation);
    handleLocationChange({
      lat: clickedLocation.lat(),
      lng: clickedLocation.lng()
    })
  });

  map.current.addListener('zoom_changed', handleZoomChange);
  
  // add locate control
  const locateDiv = document.createElement('div');
  locateDiv.style.marginRight = '10px'
  locateDiv.style.marginBottom = '30px'
  locateDiv.appendChild( createLocateControl(map.current) )
  map.current.controls[Google.maps.ControlPosition.RIGHT_CENTER].push(locateDiv);

  // search box
  const input = document.getElementById(MAP_VIEW_ID + '-searchbox');

  if (!input) return { map, marker }
  // map.current.controls[Google.maps.ControlPosition.TOP_LEFT].push(input);

  const autoComplete = new Google.maps.places.Autocomplete(input, {
    // bounds,
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    types: ["establishment"],
    componentRestrictions // 限定国家范围
  });
  // input.focus();
  // autoComplete.bindTo("bounds", map.current)
  autoComplete.addListener("place_changed", () => {
    const place = autoComplete.getPlace();
    console.log(place)
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      map.current.fitBounds(place.geometry.viewport);
    } else {
      map.current.setCenter(place.geometry.location);
      map.current.setZoom(zoom);
    }

    handleLocationChange({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    })
  })

  return { map, marker }
}

function createLocateControl(map) {
  const locateIcon = `<img style="width:20px;height:20px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABHhJREFUeF7tW0FW2zAQHTk5QJt237AI7/UUhJMAS+AQwCGAZeAkhFP0PbLA7Nu0BwBPaxuD40iaP7KcvJKwjSxp/vz5MxoJQxv+ZzbcftoCsGXAGhAYXD9MiM3hwtKGb+bHu0er3s5aQmALwJYBGxYCn65/DPvUH2YZjdnQniEaL8c6p0wmNYZTk9H9S/Iy/XP8Pe1aEzrVgMHl7NxtMGIap4bp4tfp7g0yOmRMdABybydZ/5AMnYVsyP4Np8Tmdn46Oo83ZzlTVAByj8c1vGlufCCiAJB73XB/Yo/t2D7L5+M0My/7MTSiNQBfr2fjjOkOM/NV6JjuS/pxIXJMZkgJfyvmaBZIzok5TYw5+nk8mmJr20e1AgAzvqRtktAU2Ww9Y5DhA8rB8YDQVhuCAZCNbx+vsKAyXYQKZBAAovGR63oZiPBwUANQbIb7j25WhntDimV/lgkTRjUAn69mdy61TwztI3EuGer73e8ATucnuzua+VUAfLl8OGRjJrYFVmF8ta4vBA3zkaZyVAEwuJqxzXjtohoPuca6naFjAQyA9Qxf5HCa/j4Z7ccwSjuHa0+kEGEcAIf3M/O8o6nICvounAo5JUNTyswTWitUQLn1AGcBBICTbor8i5fLOjV37Q0NSwgAl/LPT0bQ9/pDEl5ElSzo3TUrRjQ0MQNs9AfjTG98TQlAhrnWQMJTBMBFMSTtiUWTqHpYhedKi8geRQBc6CL09xVNou1vA2RBcwINMEgGILCD6yuacOPLkYgnbWAjOrAEQJFb63+28zkQ/61iv4kQ4El7TfCaYmvzNS9flgFw5PtFUOQDj7NI0bofLLZQwJuhGwQAkmMHVw+P/maGBglZB9CQ+08BIJJEd6UA/GviiR2YOBnglSURNUdmwIcSwbzzyguXKqIINiMzNL2IbTOFBECaE5iu5TrAetkhixJ++PEjgeTyfAar6AKhKgLQpsxsXwpjRVCbE6EIgNMIQJhyz6DqbOUB4MHC+zb6E1GUw1C+gF3R5TDIv5Vb2o4QAI130h90kMgArxfBRSogsPtDXUPEVQEiwpnvCwLA13rSXFJW116c8ZAT2iMuH0oUDyOY7rUtsdL79katVDhVvIMA8MWZpgGpyHzQUGexpWAmDICr9VTsVBGvkGXAIN/hB/U+HALVfny9+BhX1YDdxZC1XYz4i5vu3/NIxqNFUx1oOASqj7yhkD946OgtD1JTIJ2jJsvUALzlduGGOEuebzQXJj76F6BT78z3eiTEeLUG1DcpV3hx2IB0ekKNbwVAkRqhV2FlXy4hc4tenWuqxzbGtwbgPRyWb2bslK41KTPztPBIqngf5XpFapsNuzOQMkuQBjQnjXX0lTb7/ruuXPbNGwWANyYUL0Sll124mcsj4+hKqzQobV8Tv9JcdY93lV6jMcAWFr2sN86MOQh/QRrf41HqANxz5cicFTkY+YtQ8bl84KlQu6dqfGcM8G1o+y8zgR3cUC+vJAtoNrdlwKYzQMOWrseuRQS7Nkoz/xYADVofcexfbrtVbsXe3m8AAAAASUVORK5CYII="/>`;
  const locateLoadingIcon = locateIcon // TODO `<img style="width:20px;height:20px" src=""/>`;
  const locateErrorIcon = `<img style="width:20px;height:20px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABG9JREFUeF7tW21S2zAQXZkh00uQhl8lpyCcpHAS4CTASQinCP1VN1yCCWOrYznOOEarfSsrCW2cn6BI2re7b78UQ0f+MUcuPw0ADBZwAAR+jUcPlui6fbQhevyxXN3s+zoHcYEBgMECjswFFpNvEyKaUFnOjLWXZMzM4+s5WZsbY/LS2hc6OZlP8/d815ywUw5YjEd3AYER2XJr7f307eMRWRyzJjkATttleW2IbmMuxHwnt0RP0+XqLuGebqukADiNpxW8K29yIJIAUGndFMUD49uplVbtl9ssu0rBEb0BWEy+zUxZPoNSOqKzxry49dbWJGfMJCP67v7USZAC+1Yg3Ezz9zl4tndZLwBA4Z3ZUpZVrC5editiEP100YP/9HaJaAAA4XtfDiVUS3QfS5BRAEjCp87rASCi3UENgCO8svzNWWUfbUi+LESZKGJUA/B6dvrMsf2amUU/lwQN/V9QQH6xXJ1r9lcBsDg7vTbGPPgO2IfwzbkhF7TW3mgyRxUAr+OR9QqvPFSjIW5tQBkqK4AB8NXw61g+v3j7uEohlHYP7k4aEoYBYLWfZeeajKwy305VmBuieUn0B80VWq7AETJsBRAAnLlpGF+RLqvYnL0b6JYQABzzXyxX0PcjiiQ4iVpHhSoV384YrYVcExLAZ/6on0UIv6EC1MK4MyzgniIArInV1Vgw5ktJE0B6UIbHhUUkNMsAMDU+Yv6hpAkQvlkiEhoHNGJBIgCxLexQ0qQQvo60gLV5wQZ44BMAlcDtC/rqc8T/+/h+FyBEk0xO4EJse7/u8OUTAFy874Ailp9s4qRVvzMBmdFRwLuuGwcAEGNfx6OqYgw1MzRQyDwQqFPaB/2rAJBEuijnpAEA6MAkigBOeSk5RwTgPyNBB56KBLuOGRtepLaZhgCQGj82XIt5AMOuMimlmhUAEaAC00e6SPiUAWD6/khykiAVhpKgPhUhAoC35kaIqdIMys7eThNAttX3uJwjSTHkzMvfCBXdwAEQOSxFzLcBzWf+qIJECwhpET2kAQKcH+oaIkyxhhDnOsTKfBzwZd1lm4cSRTHJjLm0RPVDiWZeCI7P2jfmUncpcWr2gCwg5GcaK5Ch1q3gki3NnWAA2NZTPdEViyOdaPLqUPGDah92geY6oV58ilG1LHa94mCDEaGzu/P3PJLwSNncBRl2gY0V1MPRz13YegHczUW13V4n5RRIctYbgE1IEybElGWPmoFJCJDK8rKyvA29HokRXs0BGm2ksgak0xMrfC8AnCVgr8Lq0VeWPUlt9LaboU/t+gjfG4CWO3Cc0LXsTZPSzQJbj6TcZfhXpD4PgWYGEteoSdC3oWLuJ90H/b8qAw1tmgSAxhLWZiu97EKF9Gs98YvRZADE+K8CiZ2F1+QAbAFRFDNTvfXzvw5H5N+Z4M3hOwNgK2RWVWBRzKoXodJzefeKNKIqRND0rdkLAN2DYxuYsULuhQQ1lxsAGH4zdGS/GdK4x77XHoQE9y3klyPBAYAvhMBfRD5EbqkMvqYAAAAASUVORK5CYII="/>`;
  const controlButton = document.createElement("button");
  // Set CSS for the control.
  controlButton.style.width = '39.99px'
  controlButton.style.height = '38px'
  controlButton.style.display = 'flex'
  controlButton.style.justifyContent = 'center'
  controlButton.style.alignItems = 'center'
  controlButton.style.backgroundColor = "#fff";
  controlButton.style.borderRadius = "3px";
  controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.15)";
  controlButton.style.cursor = "pointer";
  controlButton.style.fontSize = 0;
  controlButton.style.lineHeight = "38px";
  controlButton.innerHTML = locateIcon
  controlButton.title = "Click to my location";
  controlButton.type = "button";
  // Setup the click event listeners: simply set the map to Chicago.
  var locating = false;
  controlButton.addEventListener("click", () => {
    controlButton.innerHTML = locateLoadingIcon;
    if (locating) return;
    locating = true
    controlButton.style.cursor = "progress";
    getCurrentLocation().then(e => {
      controlButton.innerHTML = locateIcon;
      map.setCenter(e)
    }).catch(e => {
      controlButton.innerHTML = locateErrorIcon;
    }).finally(e => {
      setTimeout(_ => controlButton.innerHTML = locateIcon, 500)
      controlButton.style.cursor = "pointer";
      locating = false;
    })
  });
  return controlButton;
}

export function geocodeLatLng(latLng = { lat: 0, lng: 0 }) {
  const Google = window.google;
  return new Google.maps.Geocoder().geocode({ location: latLng })
    .then(res => {
      console.log(res)
      if (res.results?.[0]) {
        return res.results[0]
      }
      return Promise.reject(null)
    })
}

export default GMapPicker;
