import { useCallback, useEffect, useRef, useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  useTheme,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  Chip,
  Stack,
  Button,
  ButtonGroup,
  Tooltip,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Divider from '@mui/material/Divider';

import Header from "../../components/Header";
import { tokens } from "../../theme";
import { Confirm } from 'src/components/Confirm';
import { getBetweenDates, delay } from "src/utils/Utils";
import Calendar from "src/components/Calendar";
import useIsMobile from "src/hooks/useIsMobile";
import moment from "moment";
import ButtonRadios from "src/components/ButtonRadios";
import useToast from "src/hooks/useToast";
import PropertySelector from "src/pages/booking/PropertySelector";
import { usePriceAvailableStatus, useFormSubmit, usePricesByDateRange } from "src/services/propertyPrices";
import useLocationQuery from "src/hooks/useLocationQuery";

const PropertyPrices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const toast = useToast();

  const isMobile = useIsMobile()

  const [openSelected, setOpenSelected] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const [currentMonth, setCurrentMonth] = useState(moment());
  const [calendarContinuous, setCalendarContinuous] = useState(false);

  // const { data: availableStatus = [] } = usePriceAvailableStatus();
  const availableStatus = [
    { name: 'AVAILABLE', value: 'AVAILABLE', icon: '💚', color: colors.greenAccent[400] },
    { name: 'OCCUPIED', value: 'OCCUPIED', icon: '🛌🏼', color: colors.blueAccent[400] },
    { name: 'RESERVED', value: 'RESERVED', icon: '👩🏼', color: colors.blueAccent[200] },
    { name: 'NOT_AVAILABLE', value: 'NOT_AVAILABLE', icon: '🚫', color: colors.grey[200] },
  ]
  const [availableStatu, setAvailableStatu] = useState('-');

  // const [properties, setProperties] = useState([]);
  const id = useLocationQuery('property_id', 'number');
  // console.log('id', id, 1111)
  const [property, setProperty] = useState({ id });
  const [propertyAnchorEl, setPropertyAnchorEl] = useState(null);

  const periodTypes = [
    { field: '', value: '-', icon: '' },
    { field: 'shortPrice', value: 'SHORT', icon: '🌙' },
    { field: 'longPrice', value: 'LONG', icon: '🌕' }
  ]
  const [periodType, setPeriodType] = useState('-')

  const sortings = [
    { value: '-', icon: '' },
    { value: 'BEST', icon: '💖' },
    { value: 'CHEAP', icon: '🤍' }
  ]
  const [sorting, setSorting] = useState('-')

  const {isLoading, error, data: rows = [], refetch } = usePricesByDateRange({
    property_id: property?.id, 
    available_status: availableStatu !== '-' && availableStatu,
    // period_type: periodType !== '-' && periodType, 
    // sorting: sorting !== '-' && sorting,
    dateRange: [
      currentMonth.format('YYYY-MM-01'),
      currentMonth.format(`YYYY-MM-${currentMonth.daysInMonth()}`)
    ]
  }, {
    enabled: Boolean(property?.id),
    refetchOnMount: true
  })

  // useEffect(() => {
  //   if (!isLoading && rows.length) {
  //     // TODO render item
  //   }
  // }, [isLoading])

  // const [formData, setFormData] = useState({ longPrice: '', shortPrice: '' })
  const [formData, setFormData] = useState({ price: '' })

  useEffect(() => {
    // var newFormData = { longPrice: '', shortPrice: '' };
    var newFormData = { price: '' };
    if (selectedDates.length && rows?.length) {
      console.log(rows)
      // const row = rows.filter(t => selectedDates[0].isSame(t.date, 'date'));
        // newFormData = row
      // newFormData.longPrice  = rows.find(t => selectedDates[0].isSame(t.date, 'date') && t.period_type === 'LONG')?.price || ''
      // newFormData.shortPrice = rows.find(t => selectedDates[0].isSame(t.date, 'date') && t.period_type === 'SHORT')?.price || ''
      newFormData.price = rows.find(t => selectedDates[0].isSame(t.date, 'date') && t?.price || '')
    }
    setFormData(_ => newFormData)

  }, [selectedDates])
  
  // const mutation = useMutation({
  //   mutationFn: ({ shortPrice, longPrice, ...params }) => Promise.all([
  //     axiosInstance.post('/api/property/prices', { 
  //       ...params,
  //       period_type: 'LONG', price: longPrice
  //     }),
  //     axiosInstance.post('/api/property/prices', {
  //       ...params,
  //       period_type: 'SHORT', price: shortPrice
  //     })
  //   ]).then(res => res.flat())
  // })
  const mutation = useFormSubmit({ 
    onSuccess() {
      refetch()
      toast('success!', 1)
      setOpenSelected(false)
    },
    onError(e) {
      toast(mutation.error?.message || 'request failed')
    }
  })

  const handleSubmit = async e => {
    // const price = +document.querySelector('#priceEl')?.value
    // return console.log(e)
    // if (!price || price < 0) {
    //   return alert('invalid price');
    // }

    // ! price value: '', 0, 0+
    
    // const shortPrice = e.detail.value.shortPrice.trim()
    // const longPrice = e.detail.value.longPrice.trim()
    const price = e.detail.value.price.trim()

    // if (isNaN(+shortPrice) || +shortPrice < 0) {
    //   return toast('invalid short price', -1);
    // }
    // if (isNaN(+longPrice) || +longPrice < 0) {
    //   return toast('invalid long price', -1);
    // }
    if (isNaN(+price) || +price < 0) {
      return toast('invalid price', -1);
    }

    if (mutation.isPending) return;
    const fd = {
      // shortPrice, 
      // longPrice,
      price,
      property_id: property.id,
      date: selectedDates.map(t => t.format('YYYY-MM-DD'))
    }

    mutation.mutate(fd)
  }


  return (
    <Box m="20px" sx={ !isMobile ? { width: '80%' } : {} }>
      <Box display={'flex'} gap={4} sx={{ cursor: 'pointer' }}>
        <Header 
          title={ <Box onClick={ e => setPropertyAnchorEl(e.currentTarget) }>
            Property { <span style={{color: colors.greenAccent[500], textDecoration: 'underline'}}>{ property?.property_name }'s</span> } Prices
          </Box> }  
          subtitle="Property Prices Interactive Page"
          />
          <PropertySelector defaultValue={property} hideAnchor anchorEl={ propertyAnchorEl } onClose={e => setPropertyAnchorEl(null)} onChange={ e => setProperty(e) }/>
          {/* <Dialog open={showPropertySelect} 
            onClose={ (e, reason) => ['backdropClick', 'escapeKeyDown'].includes(reason) && setShowPropertySelect(_ => false) }
            >
            <List  component="nav">
              { properties.map((t, i) => <>
                { i > 0 && <Divider/> }

                <MenuItem key={t.id} 
                  onClick={e => {
                    property.id !== t.id && setProperty({...t});
                    setShowPropertySelect(_ => false)
                  }}
                  selected={property.id === t.id}
                  >
                  { t.property_name }
                </MenuItem>
              </>
              )}
            </List>
          </Dialog> */}
        {/* <TextField
          select
          sx={{ width: "160px", height: '40px' }}
          label={ 'Check a Property' }
          value={ property.id }
          onChange={ e => setProperty(_ => properties.find(t => t.id === +e.target.value)) }
        >
          {properties.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField> */}
      </Box>

      <Calendar continuous={ calendarContinuous }
        DayItem={ ({ date, isCurrentMonth, isToday, selected }) => {
          const row = rows.find(t => t.date === date && t.price != null);
          
          return (
            <Box position={'relative'} height={'10vh'} padding={'10px'} flexShrink={0}>
              <Box>{+date.slice(-2)}</Box>
              {!!row && <Box>
                <Box fontSize={'14px'} color={ colors.greenAccent[200] }>
                  {row.price} ({row.price_currency})
                </Box>
                <Box sx={{ fontSize: '12px', }}>{ availableStatus.find(t => t.value === row.available_status)?.icon }</Box>
              </Box>
              }
            </Box> 
          )}
        }
        onMonthChange={ monthDate => {
          setCurrentMonth(_ => monthDate.clone());
        }}
        onSelect={ 
          dates => { 
            console.log(dates);
            setSelectedDates(_ => dates.map(d => moment(d)))
            delay(160).then(_ => setOpenSelected(true))
          }
        }
        HeaderActions={
          <Box gap={'10px'} flex={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <FormControlLabel control={<Switch color="info" onChange={ e => setCalendarContinuous(e.target.checked) } />} label="SELECT BY RANGE" />

            <ButtonRadios sx={{ height: 26 }} color="warning"
              options={[{ name: '-', value: '-' }].concat(availableStatus)} 
              valueKey={'value'} 
              value={ availableStatu } 
              onChange={ setAvailableStatu }
              LabeRender={ ({icon = '', name}) => name + icon }
              />

            {/* <ButtonRadios sx={{ height: 26 }} color="success"
              options={sortings} 
              valueKey={'value'} 
              value={sorting} 
              onChange={ setSorting }
              LabeRender={ ({value, icon}) => (icon + ' ' + value) }
              /> */}
          </Box>
        }
        />

      <Confirm form title="CONFIRM TO UPDATE" open={openSelected} onCancel={ e => setOpenSelected(_ => false) } onConfirm={handleSubmit} loading={mutation.isPending}>
        <Typography variant='h3'>{ currentMonth.format('YYYY-MM') }</Typography>

        <Box display="flex" flexWrap="wrap" marginTop={2} gap={2} width={560}>
          { selectedDates.map((date, i) => 
            <Chip key={i} variant="outlined" color={"info"} sx={{color: colors.grey[100], fontSize: '18px'}} label={ date.format('DD') } 
              onDelete={ e => {
                if (selectedDates.length === 1) {
                  setOpenSelected(_ => false) // the last is not necessary to set rerender
                } else {
                  setSelectedDates(_ => selectedDates.filter((t, ti) => ti !== i)) 
                }
              }
              }/>
          )}
        </Box>

        <Box mt={4}>
          <Typography variant="h6" sx={{ marginBottom: '10px', color: colors.grey[200] }}>🎈Filling in the blank (excluding 0) will be removed.</Typography>
           
          <TextField
            variant="filled"
            fullWidth
            defaultValue={ formData.price }
            label={ `Property Price (MYR)` }
            name={ 'price' }
            inputProps={{ type: 'number', min: 0, step: 0.01, autoComplete: 'off' }}
            InputLabelProps={{
              style: { color: colors.greenAccent[300] }
            }}
            sx={{ marginBottom: 2 }}
          />



        </Box>
      </Confirm>
    </Box>
  );
};

export default PropertyPrices;
