export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESSFUL = 'FETCH_PROJECT_SUCCESSFUL';
export const FETCH_PROJECT_API_FAILED = 'FETCH_PROJECT_API_FAILED';

export const FETCH_EACH_PROJECT = 'FETCH_EACH_PROJECT';
export const FETCH_EACH_PROJECT_SUCCESSFUL = 'FETCH_EACH_PROJECT_SUCCESSFUL';
export const FETCH_EACH_PROJECT_API_FAILED = 'FETCH_EACH_PROJECT_API_FAILED';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESSFUL = 'DELETE_PROJECT_SUCCESSFULLY';
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESSFUL = 'CREATE_PROJECT_SUCCESSFULLY';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESSFUL = 'UPDATE_PROJECT_SUCCESSFULLY';
export const UPDATE_PROJECT_FAILED = 'FAILED';


export const PAGINATE_UPDATE = 'PAGINATE_UPDATE'