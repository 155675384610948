import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, useTheme, Alert, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import Header from "../../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import GMapPicker from "src/components/GMapPicker";
import useIsMobile from "src/hooks/useIsMobile";
import MyDatePicker from "src/components/MyDatePicker";
import { GetProperty, UpdateProperty, CreateProperty } from "src/services/properties";
import { delay, toGamel } from "src/utils/Utils";

export default function FormItem(item) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const [value, setValue] = useState(item.value)
  // useEffect(() => setValue(item.value), [item.value])
  
  const InputLabelProps = {
    style: {
      color: colors.greenAccent[500],
      fontWeight: "bold",
    }
  }
  if (item.type === 'select') {
    const selectedValue = item.value != undefined ? item.value : '';
    return <TextField
      variant={ item.variant || "filled" }
      select
      label={ item.label || toGamel(item.field || item.name) }
      required={ item.required }
      value={ selectedValue }
      name={item.field || item.name}
      InputProps={item.InputProps || {}}
      InputLabelProps={ InputLabelProps }
      onChange={ item.onChange }
      size={ item.size }
      sx={ item.sx }
      disabled={!!item.disabled}
      >
      {item.options.map((option, i) => {
        const value = option?.hasOwnProperty('value') ? option.value : option;
        return <MenuItem key={i} value={value} 
          selected={selectedValue === value}
          disabled={!!option.disabled}
          sx={{ display: 'flex', justifyContent: 'space-between', '&.MuiMenuItem-root.Mui-selected': { backgroundColor: colors.grey[400] } }}
          >
          <div>{option.name || value}</div>
          {/* { selectedValue === value && <DoneIcon sx={{ marginLeft:'20px' }}/> } */}
        </MenuItem>
      })}
    </TextField>
  }
  // TODO：fix Popover hidden
  // if (item.type === 'date') {
  //   return <MyDatePicker variant="filled"
  //     key={item.field}
  //     label={ item.label || item.field }
  //     required={ item.required }
  //     value={ item.value }
  //     name={item.field}
  //     InputLabelProps={{
  //       style: {
  //         color: colors.greenAccent[500],
  //         fontWeight: "bold",
  //       },
  //     }}
  //     onChange={ item.onChange }
  //     />
  // }
  const inputProps = item.inputProps || {}
  if (item.type === 'number') {
    inputProps.type = 'number'
    inputProps.step = isNumber(item.step) 
      ? item.step 
      : isNumber(inputProps.step) 
        ? inputProps.step 
        : '0.01'
  }
  return <TextField
    variant={ item.variant || "filled" }
    disabled={!!item.disabled}
    type={item.type}
    label={ item.label || item.field }
    required={ item.required }
    value={ (item.type === 'date' && item.value) ? moment(item.value).format('YYYY-MM-DD') : (item.value || '') }
    name={item.field || item.name}
    multiline={item.rows > 1}
    rows={item.rows || 1}
    InputLabelProps={ InputLabelProps }
    InputProps={item.InputProps || {}}
    inputProps={inputProps}
    onChange={ item.onChange }
    size={ item.size }
    sx={ item.sx }
    />
}

function isNumber(value) {
  return typeof value === 'number' && !isNaN(+value);
}