import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, Chip, useTheme, Alert, MenuItem, Stack, Typography } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from "@mui/lab/LoadingButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import SaveIcon from "@mui/icons-material/Save";
import Header from "src/components/Header";
import Calendar from "src/components/Calendar";

import { tokens } from "src/theme";
import useIsMobile from "src/hooks/useIsMobile";
import useLocationQuery from "src/hooks/useLocationQuery";
import { useProperty } from "src/services/properties";

import useToast from "src/hooks/useToast";
import PropertyImageCard from "../properties/ImageCard";
import { useElectricUsages } from "src/services/electricUsages";
import moment from "moment";

const ElectricUsages = (props) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();

  const toast = useToast({ autoHideDuration: 2000 });

  const property_id = useLocationQuery('property_id', 'number');
  const { data: property } = useProperty({ id: property_id })

  const [currentMonth, setCurrentMonth] = useState(moment());

  const dateStart = currentMonth.format('YYYY-MM-01')
  const dateEnd = currentMonth.format(`YYYY-MM-${currentMonth.daysInMonth()}`)

  const { data: rows = [] } = useElectricUsages({ 
    property_id, 
    dateStart,
    dateEnd
  })

  const boxProps = {
    mt: 3,
    display: 'flex', flexWrap: 'wrap', gap: '30px',
    width: isMobile ? '100%' : '70%',
    autoComplete: 'off',
    sx: {
      '& .MuiTextField-root': { m: 1, width: '40%' },
    }
  }

  return ( 
    <Box m="20px" pb="10px">
      <Box display={'flex'} alignItems={'center'} gap={4}>
        <Header
          title={"Electric Usage"} mb={0}
          />
      </Box>

      <Box {...boxProps} alignItems={'center'}>
        {/* <FormItem label={toGamel('payout_year_month')} value={formData.payout_year_month} type="month" disabled/> */}

        { !!property && <Box marginLeft={1} display={'flex'} gap={1} flexDirection={'column'}>
            {/* <Typography variant="h5" color={colors.greenAccent[400]} fontWeight={'bold'}>Property: </Typography> */}
            <PropertyImageCard property={property}/>
          </Box>
        }
      </Box>
          
      <Box sx={ !isMobile ? { width: '80%' } : {} }>
        <Calendar selectable={false} maxMonth={moment()}
          DayItem={ ({ date, isCurrentMonth, isToday, selected }) => {
            const row = rows.find(t => t.date === date);
            
            return (
              <Box position={'relative'} height={'10vh'} padding={'10px'} flexShrink={0}>
                <Box>{+date.slice(-2)}</Box>
                {!!row && <Box>
                  <Box fontSize={'14px'} color={ colors.greenAccent[200] }>
                    {row.amount}
                  </Box>
                </Box>
                }
              </Box> 
            )}
          }
          onMonthChange={ monthDate => {
            setCurrentMonth(monthDate.clone());
          }}
        />
      </Box>
      
    </Box>
  );
};

export default ElectricUsages;
