// routes/index.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dashboard from '../pages/dashboard';
import Owner from '../pages/owner';
import Properties from '../pages/properties';
import PropertyForm from '../pages/properties/form';
import Project from '../pages/project';
import Bookings from 'src/pages/booking';
import BookingForm from 'src/pages/booking/form';
import Bar from '../pages/bar';
import Pie from '../pages/pie';
import Line from '../pages/line';
import Feedback from '../pages/feedback';
import PropertyPrices from '../pages/propertyPrices';
import Geography from '../pages/geography';
import Login from '../pages/login';
import ProjectForm from '../pages/project/projectForm';
import News from 'src/pages/news';
import NewsForm from 'src/pages/news/form';
import Payment from 'src/pages/payment';
import Payout from 'src/pages/payout';
import PayoutForm from 'src/pages/payout/form';
import Electric from 'src/pages/electric';

function AppRoutes({ isAuthenticated }) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
        }
      />
      <Route path="/login" element={<Login />} />
      {isAuthenticated ? (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/owner" element={<Owner />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/property-form" element={<PropertyForm />} />
          <Route path="/propertyPrices" element={<PropertyPrices />} />

          <Route path="/project" element={<Project />} />
          <Route path="/project-form" element={<ProjectForm />} />

          <Route path="/booking" element={<Bookings />} />
          <Route path="/booking-form" element={<BookingForm />} />

          <Route path="/news" element={<News />} />
          <Route path="/news-form" element={<NewsForm />} />

          <Route path="/feedback" element={<Feedback />} />
          
          <Route path="/payment" element={<Payment />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/payout-form" element={<PayoutForm />} />

          <Route path="/electric" element={<Electric />} />

          <Route path="/bar" element={<Bar />} />
          <Route path="/pie" element={<Pie />} />
          <Route path="/line" element={<Line />} />
          <Route path="/geography" element={<Geography />} />
        </>
      ) : (
        <Route path="/login" element={<Login />} />
      )}
    </Routes>
  );
}

AppRoutes.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
};

export default AppRoutes;
