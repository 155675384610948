import { useEffect, useState } from 'react';
import { Typography, TextField, Box, Alert, useTheme, Button, Chip ,Pagination, Avatar, Divider, Dialog } from "@mui/material";
import { AddPhotoAlternate, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { DataGrid } from "@mui/x-data-grid";
import Header from 'src/components/Header';
import moment from 'moment';
import useSelectFields from './useSelectFields';
import FormItem from '../properties/formItem';
import { asset, toGamel } from 'src/utils/Utils';
import { tokens } from "src/theme";
import useTopBarSearch from 'src/hooks/useTopBarSearch';
import { usePayments, useFormSubmit } from 'src/services/payments';
import ImagePreview from 'src/components/Uploader/ImagePreview';
import useToast from 'src/hooks/useToast';
import Confirm from "src/components/Confirm";
import PropertyImageCard from 'src/pages/properties/ImageCard';
import MyTable from 'src/components/MyTable';
import BookingDetail from '../booking/detail';


export default function Payments() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const toast = useToast()
  
  const [previewImages, setPreviewImages] = useState([])
  const [currentBooking, setCurrentBooking] = useState(null)

  const [types, setTypes] = useState({
    payment_status: ''
  })

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })
  
  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    setSearchKeyword(keyword?.trim() || '')
  })

  const { paginate, setPaginate, isLoading, error, data = { rows: [], count: 0 }, refetch } = usePayments({
    ...types,
    searchKeyword
  })

  if (error) {
    toast(error.message)
  }

  // console.log(data)
  const { rows, count } = data

  useEffect(() => {
    if (!isLoading && count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  const mutation = useFormSubmit({
    onSuccess: _ => {
      toast('Success !', 1)
      refetch()
    },
    onError: _ => toast('Delete Failed !')
  })

  const [replyAnswer, setReplyAnswer] = useState('');
  const handleRepley = ({ row, index, column }) => {
    Confirm.open({
      title: <p>
        Reply for Payment <Typography variant='subtitle1'>{ row.enquiry }</Typography>
      </p>,
      content: <Box>
        <TextField color='success' defaultValue={row.answer} label={ row.answer ? "Update answer" : "" } sx={{width: '300px', fontSize: '18px'}} multiline rows={4} autoFocus 
          onChange={ e => setReplyAnswer(_ => e.target.value) }
          />
      </Box>, 
      confirmText: 'Reply',
      confirmColor: 'success',
      ConfirmIcon: <EditOutlined />,
      onCancel: _ => Confirm.close(),
      onConfirm: e => {
        console.log(replyAnswer)
        if (!replyAnswer.trim()) {
          toast('Invalid Answer')
        } else {
          mutation.mutate({ id: row.id, answer: replyAnswer })
          Confirm.close()
        }
      },
    })
  }

  const columns = [
    { field: "id", headerName: "ID", with: 40 },
    { field: "user", headerName: "User", width: 140, cellClassName: 'content-wrap',
      renderCell: ({ row: { user } }) => 
        user 
          ? <>
              <Box>{user.username}</Box>
              <Box>Role: {user.role}</Box>
              <Box>Mobile No:{user.phone}</Box>
              <Box style={{ color: '#4fc1ff' }}>Email: {user.email}</Box>
            </>
          : '-'
      ,
    },
    { field: "property", headerName: "Property", flex: 3, cellClassName: 'content-wrap',
      renderCell: ({ row: { property } }) => 
        property 
          ? <PropertyImageCard width="80px" imageHeight="40px" previewImage property={property}/>
          : '-'
      ,
    },
    { field: "booking", headerName: "Booking", flex: 3, cellClassName: 'content-wrap',
      renderCell: ({ row: { property, booking } }) => 
        booking
          ? <Button color='info' sx={{ display: 'flex', flexDirection: 'column' }} onClick={ e => setCurrentBooking({ ...booking, property }) }>
              <Box>{booking.guest_email}</Box>
              <Box>{booking.date_start} ~ {booking.date_end}</Box>
            </Button>
          : '-'
    },
    { field: "amount", headerName: 'Amount', flex: 2, cellClassName: 'content-wrap' },
    { field: "currency", headerName: 'Currency', flex: 2, cellClassName: 'content-wrap' },
    { field: "discount", headerName: 'Discount', flex: 2, cellClassName: 'content-wrap' },
    { field: "total_amount", headerName: toGamel('total_amount'), flex: 2, cellClassName: 'content-wrap' },
    { field: "payment_invoice", headerName: toGamel('invoice'), flex: 2, cellClassName: 'content-wrap' },
    { field: "tax_fee", headerName: toGamel('tax_fee'), flex: 2, cellClassName: 'content-wrap' },
    { field: "payment_status", headerName: toGamel('status'), width: 120, cellClassName: 'content-wrap',
      renderCell: ({ row: { payment_status } }) => {
        // 'RESERVED','PAYMENT_PENDING','CONFIRMED','CANCELLED','PAYMENT_FAILED'
        const icon = { PENDING: '⏳', FAILED: '💔', PAID: "✅", REFUNDED: "🔙", CANCELLED: '🚫' }[payment_status]
        return (
          payment_status
            ? <Chip title={payment_status} variant="outlined" color={ 'warning' } size="small" label={ payment_status + ' ' + icon }/>
            : '-'
        );
      },
    },
    { field: "date", headerName: "CreatedAt", flex: 2,  width: '120px', cellClassName: 'content-wrap',
      renderCell: ({ row }) => <>
        <Box>{ moment(row.created_at).format('YYYY-MM-DD HH:mm') } </Box>
      </>
    },
  ]

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          sx={{ flex: 1, marginBottom: 0 }}
          title="Payments"
          subtitle="List of Payments"
          />
        
        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'}>
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '150px' }} name={t.field} onChange={t.onChange}/>
          )}
        </Box>
      </Box>
      
      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        rowHeight={95}
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      
      { previewImages.length ? <ImagePreview files={previewImages} onClose={ e => setPreviewImages([]) } currentIndex={0}/> : '' }

      <Dialog open={ !!currentBooking } onClose={ e => setCurrentBooking(null) }>
        <Box padding={2}>
          { !!currentBooking && <BookingDetail booking={ currentBooking }/> }
        </Box>
      </Dialog>
    </Box>
  );
}