import React, { useEffect, useState } from "react";
import { Box, Button, TextField, useTheme, Alert, Stack } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import {
  createProject,
  createProjectFailed,
  updateProject,
  fetchEachProject,
} from "../../store/project/actions";
import GMapPicker from "src/components/GMapPicker";
import useIsMobile from "src/hooks/useIsMobile";
import useLocationQuery from "src/hooks/useLocationQuery";

const ProjectForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { message, loading, projectError, project } = useSelector(
    (state) => state.Project
  );
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState({
    name: "",
    longitude: '',
    latitude: '',
  });

  const id = useLocationQuery('id', 'number');
  useEffect(() => {
    if (+id) {
      dispatch(fetchEachProject(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (project) {
      setFormData({
        name: project.name || "",
        latitude: project.latitude || '',
        longitude: project.longitude || '',
      });
    }
  }, [project]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePositionChange = ({ lat, lng, target }) => {
    if (target?.name) handleFormChange({ target })
    else setFormData(prevFormData => ({
      ...prevFormData,
      latitude: lat,
      longitude: lng
    }))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (project) {
      dispatch(updateProject({ id, ...formData }));
    } else {
      dispatch(createProject(formData));
    }
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        navigate("/project");
        dispatch(createProjectFailed(""));
      }, 3000);
    }
  }, [dispatch, message, navigate]);

  useEffect(() => {
    if (projectError) {
      setTimeout(() => {
        dispatch(createProjectFailed(""));
      }, 3000);
    }
  }, [dispatch, projectError]);

  useEffect(() => {
    dispatch(createProjectFailed(""));
  }, [dispatch]);

  return (
    <Box m="20px" pb="10px">
      <Header
        title={`${project ? "UPDATE PROJECT" : "CREATE PROJECT"}`}
        subtitle={`${
          project ? "Update existing Project" : "Create a New Project"
        }`}
      />
      <div className="mb-4 w-8/12">
        {message && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}
        {projectError && (
          <Alert variant="filled" severity="error">
            {projectError}
          </Alert>
        )}
      </div>
      { !loading ? (
      <form onSubmit={handleFormSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap="30px"
          width={isMobile ? "100%" : "70%"}
          autoComplete='off'
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Project Name"
            name="name"
            InputLabelProps={{
              style: {
                color: colors.greenAccent[500],
                fontWeight: "bold",
              },
            }}
            value={formData.name}
            onChange={handleFormChange}
            required
          />
          <Box display={isMobile ? "block" : "flex"} gap="30px">
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Latitude"
              name="latitude"
              InputLabelProps={{
                style: {
                  color: colors.greenAccent[500],
                  fontWeight: "bold",
                },
              }}
              InputProps={{
                autoComplete: 'off',
                readOnly: true
              }}
              value={formData.latitude}
              onChange={handleFormChange}
              required
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Longitude"
              name="longitude"
              InputLabelProps={{
                style: {
                  color: colors.greenAccent[500],
                  fontWeight: "bold",
                },
              }}
              InputProps={{
                autoComplete: 'off'
              }}
              value={formData.longitude}
              onChange={handleFormChange}
              required
            />
          </Box>
        </Box>
        <Box
          mt={4} mb={10}
          width={isMobile ? "100%" : "70%"}
          >
          <GMapPicker
            defaultLocation={project && { lat: project.latitude, lng: project.longitude }}
            onLocationChange={ handlePositionChange }
            onZoomChange={e => console.log(e)}
          />
        </Box>
        <Box
          mt={4} mb={10}
          display="flex"
          justifyContent="flex-end"
          width={isMobile ? "100%" : "70%"}
          gap="10px"
        >
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {project ? "Update Project" : "Create New Project"}
          </LoadingButton>
          <Link to="/project" style={{ textDecoration: "none" }}>
            <Button color="info" variant="contained">
              Back
            </Button>
          </Link>
        </Box>
      </form>
      ) : (
        <Stack spacing={4} mt={4}>
          <Skeleton variant="rectangular" width={300} height={60}/>
          <Skeleton variant="rectangular" width={isMobile ? '100%' : '70%'} height={60}/>
          <Skeleton variant="rounded" width={isMobile ? '100%' : '70%'} height={'30vh'} />
        </Stack>
      )}
    </Box>
  );
};

export default ProjectForm;
