import { useState, Fragment, useRef } from "react";
import { Box, Typography, Chip, TextField, useTheme, Button, alpha  } from "@mui/material";

// import { PickerBase } from "mui-daterange-picker-plus";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";

import { Calendar } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"

import { tokens } from "src/theme";
import moment from "moment";

import { Confirm } from "src/components/Confirm";
import { useFormSubmit, usePriceAvailableStatus } from "src/services/propertyPrices";
import useToast from "src/hooks/useToast";
import { getBetweenDates } from "src/utils/Utils";
const formater = 'YYYY-MM-DD'
// let touching = false;
export default function SettingPricesDialog({ open = false, onClose = _ => null, propertyIds = [] }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [currentMonth, setCurrentMonth] = useState(moment());
  
  const toast = useToast();

  const periodTypes = [
    { field: 'shortPrice', value: 'SHORT', icon: '🌙' },
    { field: 'longPrice', value: 'LONG', icon: '🌕' }
  ]
  const [dateRange, setDateRange] = useState([]);

  const [formData, setFormData] = useState({ longPrice: '', shortPrice: '' })
  const { data: availableStatus = [] } = usePriceAvailableStatus()

  const mutation = useFormSubmit();

  const [selectedDates, setSelectedDates] = useState([]);

  const touching = useRef(false)

  const handleDateSelect = (date, mi) => {
    if (!touching.current) {
      setDateRange([date])
      setSelectedDates([])
    } else {
      setDateRange([dateRange[0], date])
    }
    touching.current = !touching.current
    console.log(selectedDates)
  }
  
  const handleDateSelecting = (date, mi) => {
    if (touching.current) {
      const betweens = moment(dateRange[0]).isAfter(date, 'date')
        ? [date, dateRange[0]]
        : [dateRange[0], date];

      const dates = getBetweenDates(...betweens).map(d => moment(d).format(formater))
      setSelectedDates(_ => dates)
    }
  }

  const selectedColor = date => {
    const [startDate, endDate] = dateRange
    if (date === startDate || date === endDate) {
      return colors.blueAccent[600]
    }
    if (selectedDates.includes(date)) {
      return colors.grey[700]
    }
    return 'none'
  }
  
  const handleDatesSelected = e => {

    const newSelectedDates = []
    e.forEach(ds => {
      if (ds.length === 1) {
        newSelectedDates.push(ds[0].format())
      }
      if (ds.length === 2) {
        newSelectedDates.push(
          ...getBetweenDates( ...ds.map(d => d.format()) )
            .map(d => moment(d).format(formater))
        )
      }
    })

    // console.log(newSelectedDates)
    formData.dates = newSelectedDates
  }

  const handleSubmit = e => {
    const { price } = e.detail.value
    
    if (isNaN(+price) || +price < 0) {
      return toast('invalid price', -1);
    }
    
    if (!formData.dates.length) {
      return toast('invalid date', -1);
    }
    
    if (mutation.isPending) return;
    
    const fd = {
      // shortPrice, 
      // longPrice,
      price,
      property_id: propertyIds,
      date: formData.dates
    }
    
    mutation.mutate(fd)
    onClose(e)
  }

  const [months, setMonths] = useState([moment(), moment().add(1, 'month')]);
  
  return <Confirm offsetTop="0" form open={open} onCancel={ e => onClose(e) } onConfirm={handleSubmit} loading={mutation.isPending}>
    {/* <Typography variant='h3'>{ currentMonth.format('YYYY-MM') }</Typography> */}
    
    <Box width={'630px'} sx={{ 
        // '& .MuiGrid2-root': { backgroundColor: colors.primary[400], 'MuiBox-root': { backgroundColor: 'none' } },
        // '& .MuiInputBase-root': { backgroundColor: colors.blueAccent[300] },
        // '& .MuiListItemText-primary': { color: colors.grey[100] },
        // '& .MuiButtonBase-root.MuiIconButton-root': { backgroundColor: colors.blueAccent[600] }
        // '& li.MuiListItem-root MuiListItemButton-root:hover': { backgroundColor: colors.grey[200] },
        '& .rmdp-shadow': { boxShadow: 'none' }
      }}>
      {/* <PickerBase sx={{ backgroundColor: colors.primary[500] }} onChange={ 
        ({ startDate, endDate }) => setDateRange([ 
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
          ]) 
        }/> */}
      <Calendar multiple numberOfMonths={2} plugins={[ <DatePanel /> ]} range 
        format={formater}
        className={ ['purple', theme.palette.mode == 'dark' && 'bg-dark'].join(' ') }
        // onChange={ e => { e?.length === 2 && setDateRange([ ...e.map(d => d.format()) ]) } }
        onChange={ handleDatesSelected }
      />

      {/* <TextField variant="filled" fullWidth inputProps={{ readOnly: true, autoComplete: 'off' }} value={dateRange.join(' ~ ')} 
        InputLabelProps={{
          style: { color: colors.greenAccent[300] }
        }}/> */}

      {/* <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box display={'flex'} justifyContent={'center'}>
          { months.map((m, mi) => 
            <DateCalendar key={mi} defaultValue={ m }
              slots={{
                day: ({day, outsideCurrentMonth }) => {
                  // const selected = dateRange[mi] && day.isSame(dateRange[mi], 'date');
                  const selected = day.isSame(dateRange[0], 'date') || day.isSame(dateRange[1], 'date')

                  return <Box component={ 'span' } sx={{ flexShrink: 0, display: 'inline-flex', verticalAlign: 'middle', margin: '0 2px', width:'36px', height: '36px' }}>
                    { !outsideCurrentMonth && 
                      <Button color="info" 
                        sx={{ 
                          minWidth: '100%', padding: 0,
                          backgroundColor: selectedDates.includes(day.format(formater)) 
                            ? alpha(colors.blueAccent[400], .5) 
                            : 'transparent',
                          borderRadius: dateRange.length === 2 && selected
                            ? day.isSame(dateRange[0], 'date') ? '100% 0 0 100%' : '0 100% 100% 0'
                            : '100%'
                        }}
                        variant={ selected ? 'contained' : 'text' } 
                        onClick={ e => handleDateSelect(day, mi) }
                        onMouseMove={ e => handleDateSelecting(day, mi) }
                        >{ day.format('D') }</Button>
                    }
                  </Box>
                }
              }}
              onChange={console.log}
            />
          )}
        </Box>
      </LocalizationProvider> */}
    </Box>

    <Box mt={4}>
      <Typography variant="h6" sx={{ marginBottom: '10px', color: colors.grey[200] }}>🎈Filling in the blank (excluding 0) will be removed.</Typography>

      {/* { periodTypes.map(t => <>
        { t.field && 
          <TextField
            key={t.field}
            variant="filled"
            fullWidth
            defaultValue={ formData[t.field] }
            label={ `${t.icon} ${t.value} Property Price (MYR)` }
            // id="priceEl"
            name={ t.field }
            InputProps={{ type: 'number', autoComplete: 'off' }}
            InputLabelProps={{
              style: { color: colors.greenAccent[300] }
            }}
            sx={{ marginBottom: 2 }}
          />
        }
      </>
      )} */}
      <TextField
        variant="filled"
        fullWidth
        label={ `Property Price (MYR)` }
        name={ 'price' }
        inputProps={{ type: 'number', min: 0, step: 0.01, autoComplete: 'off' }}
        InputLabelProps={{
          style: { color: colors.greenAccent[300] }
        }}
        sx={{ marginBottom: 2 }}
      />
      
      {/* <TextField select
        options={ availableStatus.map(t => ({ name: t, value: t })) }
      /> */}
    </Box>
</Confirm>
}