import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, Chip, useTheme, Alert, MenuItem, Stack, Typography } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from "@mui/lab/LoadingButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import SaveIcon from "@mui/icons-material/Save";
import Header from "src/components/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { tokens } from "src/theme";
import GMapPicker from "src/components/GMapPicker";
import FormItem from "src/pages/properties/formItem";
import useIsMobile from "src/hooks/useIsMobile";
import useLocationQuery from "src/hooks/useLocationQuery";
import { GetPayout, UpdatePayout, CreatePayout } from "src/services/properties";

import { delay, toGamel } from "src/utils/Utils";

import { Save, StartOutlined } from "@mui/icons-material";

import Uploader from 'src/components/Uploader';
import axiosInstance from "src/utils/axiosInstance";
import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import useToast from "src/hooks/useToast";
import RichEditor from "src/components/RichEditor";
import useSelectFields from "./useSelectFields";
import { useFormSubmit, usePayout } from "src/services/payouts";
import PropertyImageCard from "../properties/ImageCard";
import PropertySelector from "../booking/PropertySelector";

const PayoutForm = (props) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();

  const toast = useToast({ autoHideDuration: 2000 });

  const [message, setMessage] = useState({ text: '', state: 'error' });

  const id = useLocationQuery('id', 'number');
  const property_id = useLocationQuery('property_id', 'number');
  const payout_year_month = useLocationQuery('payout_year_month');
  const { state } = useLocation()

  const [payout, setPayout] = useState(state?.payout || null);

  const [property, setProperty] = useState(state?.property || (property_id ? { id: property_id } : null));
  // const [propertyAnchorEl, setPropertyAnchorEl] = useState(null);

  const [formData, setFormData] = useState({
    maintenance_fee: 0,
    currency: 'MYR',
    other_fee: 0,
    service_fee: 0,
    total_payout: 0,
    water_bill: 0,
    payout_status: 'DUE',
    payout_year_month,
  });

  const { isLoading: loading, error, data, refetch } = usePayout({ id }, { enabled: !payout && !!id })

  useEffect(() => {
    if (id && !loading) { 
      if (data) {
        setPayout({ ...data });
        Object.keys(formData).forEach(k => {
          formData[k] = data[k] || ''
        })
        setProperty(data.property)
        setFormData({ ...formData });
      } else {
        setMessage({ text: 'Payout not found', state: 'error' })
      }
    }
    if (error) {
      setMessage({ text: error?.message || 'Get Payout Failed !', state: 'error' })
    }
  }, [id, loading]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, formData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const mutation = useFormSubmit({
    onSuccess() {
      toast('success!', 1)
      delay(1500).then(_ => window.history.go(-1))
    },
    onError() {
      toast(mutation.error?.message || 'request failed', -1)
    }
  })

  const handleFormSubmit = async e => {
    e.preventDefault()
  
    if (mutation.isPending) {
      return toast('Submiting')
    };

    formData.id = id
    formData.property_id = property.id

    mutation.mutate(formData);
  }
  

  const selectFields = useSelectFields({ onFormChange: handleFormChange })

  const textFields = useMemo( 
    _ => Object.keys(formData).map(field => {
      const item = { type: 'text', field, required: false, label: toGamel(field), onChange: handleFormChange }
      if ([...selectFields.map(f => f.field), 'payout_year_month'].includes(field)) {
        return false;
      }
      if (['maintenance_fee', 'other_fee', 'service_fee'].includes(field)) {
        item.type = 'number'
      }
      return item
    }).filter(Boolean), 
    [selectFields]
  )

  const boxProps = {
    mt: 3,
    display: 'flex', flexWrap: 'wrap', gap: '30px',
    width: isMobile ? '100%' : '70%',
    autoComplete: 'off',
    sx: {
      '& .MuiTextField-root': { m: 1, width: '40%' },
    }
  }

  return ( 
    <Box m="20px" pb="10px">
      <Box display={'flex'} alignItems={'center'} gap={4}>
        <Header
          title={"Payout"} mb={0}
          />
      </Box>

      <div className="mb-4 w-8/12">
        {message.text && (
          <Alert variant="filled" severity={message.state}>
            {message.text}
          </Alert>
        )}
      </div>
      
      { !loading ? (
      <form onSubmit={handleFormSubmit}>
        <Box {...boxProps} alignItems={'center'}>
          {/* <FormItem label={toGamel('payout_year_month')} value={formData.payout_year_month} type="month" disabled/> */}

          { !!property && <Box marginLeft={1} display={'flex'} gap={1} flexDirection={'column'}>
              {/* <Typography variant="h5" color={colors.greenAccent[400]} fontWeight={'bold'}>Property: </Typography> */}
              <PropertyImageCard property={property}/>
            </Box>
          } 
          {/* <PropertySelector disabled={!property_id && !!id} defaultValue={ property } hideAnchor anchorEl={ propertyAnchorEl } onClose={e => setPropertyAnchorEl(null)} onChange={ e => setProperty(e) }/>  */}
          <Typography variant="h2" color={colors.greenAccent[400]} fontWeight={'bold'}>
            { formData.payout_year_month }
          </Typography>

        </Box>
        
        <Box { ...boxProps }>
          { textFields.map(item => <FormItem value={formData[item.field]} key={item.field} { ...item }/>) }
        </Box>

        <Box { ...boxProps }>
          { selectFields.map(item => <FormItem value={formData[item.field]} key={item.field} type="select" { ...item }/>) }
        </Box>
        
        <Box { ...boxProps } mt={4} mb={4} gap={2} justifyContent="start" >

          <Button type="submit" color="secondary" variant="contained">
            Confirm Submit
          </Button>

          <Link to="/payout" style={{ textDecoration: "none", marginLeft: '40px' }}>
            <Button color="info" variant="contained">Back </Button>
          </Link>
        </Box>
      </form>
      ) : (
        <Stack spacing={4} mt={4}>
          { Array(2).fill().map((_, i) => 
            <>
              <Skeleton key={i} variant="rectangular" width={300} height={50}/>
              <Skeleton key={i + 2} variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
            </>)
          }
          <Skeleton variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
          <Skeleton variant="rounded" width={isMobile ? '100%' : '70%'} height={'30vh'} />
        </Stack>
      )}
    </Box>
  );
};

export default PayoutForm;
