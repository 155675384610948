import { useEffect, useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { Box, Alert, useTheme, Button, Chip , Popover, Divider, alpha } from "@mui/material";
import { Stack, Skeleton } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead, TableFooter, Paper, Pagination, Avatar } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DeleteProperty, useProperties } from "src/services/properties";
import Confirm from "src/components/Confirm";
import { delay, asset, toTime, toGamel, ellipsis } from "src/utils/Utils";
import { DeleteOutline, EditOutlined, BookOnline, PriceChange, ElectricBolt } from "@mui/icons-material";
import GMapPicker from "src/components/GMapPicker";
import useTopBarSearch from "src/hooks/useTopBarSearch";
import ImagePreview from "src/components/Uploader/ImagePreview";
import useSelectFields from "./useSelectFields";
import FormItem from "./formItem";
import PropertyImageCard from './ImageCard';
import MyTable from "src/components/MyTable";
import SettingPricesDialog from "./SettingPricesDialog";
import { GetPayouts } from "src/services/payouts";
import { Calendar } from "react-multi-date-picker";
import useTableColumnsByRole from "src/hooks/useTableColumnsByRole";
import useHasAccessByRole from "src/hooks/useHasAccessByRole";
const qs = require('qs');

const Properties = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const hasAccess = useHasAccessByRole()

  const [gmap, setGmap] = useState(null);

  const [message, setMessage] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState('');
  useTopBarSearch(keyword => {
    // console.log(keyword)
    setSearchKeyword(keyword?.trim() || '')
  })

  const [types, setTypes] = useState({
    furnish_type: '', zone_type: '', view_type: '', room_type: '',
    owner_id: '', project_id: ''
  })

  const typesFields = useSelectFields({ 
    clearable: true,
    onFormChange: e => {
      console.log(e)
      const { name, value } = e.target
      types[name] = value
      setTypes(_ => ({...types }))
    } 
  })

  const { paginate, setPaginate, isLoading, error, data, refetch } = useProperties({ ...types, searchKeyword })

  const { rows, count } = data || { rows: [], count: 0 };

  useEffect(() => {
    if (count) {
      setPaginate({ ...paginate, total: count, pageCount: Math.ceil(count/paginate.pageSize) })
    }
  }, [isLoading, paginate.count])

  if (error) {
    setMessage({ state: 'error', text: error.message || 'Load properties failed !' })
  }

  const [previewImages, setPreviewImages] = useState([])
  const handleImagePreview = images => {
    setPreviewImages(
      images.map(t => ({ url: asset(t.image_url) }))
    )
  }

  const handleRemove = ({ row, index, column }) => {
    Confirm.open({
      title: <h1 style={{ color: colors.red[400],fontWeight: "bold", fontSize: '20px' }}>Confirm to remove this property.</h1>,
      content: <div style={{ width: '300px', fontSize: '20px' }}>
        <p>{row.id} | { row.property_name } | { row.owner?.username } | ...</p>
      </div>, 
      confirmText: 'Delete',
      onCancel: _ => Confirm.close(),
      onConfirm: async e => {
        try {
          await DeleteProperty(row.id)
          setMessage({ message: 'Delete Success !', state: 'success' })
        } catch (e) {
          setMessage({ message: e?.message || 'Delete Failed !', state: 'error' })
        }
        Confirm.close()
        refetch()
        await delay(2000)
        setMessage(_ => null)
      },
      ConfirmIcon: <DeleteOutlineIcon />
    })
  }

  const [selectedRowIds, setSelectedRowIds] = useState([])
  const handleRowSelect = e => {
    setSelectedRowIds(e)

  }

  const [pricesDialogShow, setPricesDialogShow] = useState(false)
  const [payoutMonthAnchorEl, setPayoutMonthAnchorEl] = useState(null)
  const hanlePayoutForm = async e => {
    const payout_year_month = e.format()
    const property_id = +payoutMonthAnchorEl.dataset.rowid
    setPayoutMonthAnchorEl(null)
    const payouts = await GetPayouts({ property_id, payout_year_month })
    if (payouts?.length) {
      navigate('/payout-form?id=' + payouts[0].id, {
        state: {
          payout: payouts[0]
        }
      })
    } else {
      navigate('/payout-form?' + qs.stringify({ property_id, payout_year_month }), {
        state: { property: rows.find(t => t.id === property_id) }
      })
    }
  }

  const columns = useTableColumnsByRole([
    { field: "id", headerName: "ID", align: 'left', resizable: false, width: 40 },
    { field: "property", headerName: "Name", width: 120, minWidth: 100, cellClassName: 'content-wrap',
      renderCell: ({ row }) => <PropertyImageCard width="90px" imageHeight="40px" previewImage property={row}/>
    },
    { field: "rating", headerName: "Rating", width: 80,
      valueFormatter: v => v || '-'
    },
    { field: "owner", headerName: "Owner", minWidth: 120, cellClassName: 'content-wrap',
      renderCell: ({ row }) => 
        row.owner 
         ? <>
            <Box>{ row.owner.username }</Box>
            <Box style={{ color: '#4fc1ff' }}>{ row.owner.email }</Box>
           </>
         : '-'
    },
    { field: "project", headerName: "Project", width: 100, cellClassName: "content-wrap", 
      valueFormatter: v => v.name || '-'
    },
    { field: "build_up_size", headerName: "Build Up Size", width: 100,
      valueFormatter: v => v || '-'
    },
    { field: "description", headerName: "Description", width: 140, cellClassName: 'content-wrap',
      renderCell: ({ row }) =>
      <>
        <span title={ row.short_description + '\n' + row.long_description }>{ ellipsis(row.short_description, 20) } {row.short_description?.length < 20 && '...'}</span> <br/>
        {/* <span title={ row.long_description }>{ ellipsis(row.long_description) }</span> <br/> */}
      </>
    },
    { field: "number_of", headerName: "Number Of", width: 80, cellClassName: 'content-wrap',
      renderCell: ({ row }) =>
      <>
        <Box title="Parking">Parking: { row.number_of_parking }</Box> <Divider/>
        {/* <span title="Rooms">Rooms: { row.number_of_rooms }</span> <br/> */}
        <Box title="Toilets">Toilets: { row.number_of_toilets }</Box>
      </>
    },
    
    { field: "unit_number", headerName: "Unit Number", width: 100, cellClassName: 'content-wrap',
      valueFormatter: v => v || '-'
    },
    { field: "posted_date", headerName: "Posted Date", width: 100,
      renderCell: ({ row }) =>
        <span title="Posted Date">{ new Date(row.posted_date).toLocaleDateString() }</span>
    },
    // { field: "view_type", headerName: "Property View", flex: 1, },
    // { field: "furnish_type", headerName: "Furnish Type", flex: 1, 
    //   renderCell: ({ row }) => toGamel(row.furnish_type)
    // },
    // { field: "zone_type", headerName: "Property Type", flex: 1, 
    //   renderCell: ({ row }) => toGamel(row.room_type)
    // },
    // { field: "room_type", headerName: "Property Type", flex: 1, 
    //   renderCell: ({ row }) => toGamel(row.room_type)
    // },
    { field: "type", headerName: "Type Of", width: 180, cellClassName: 'content-wrap',
      renderCell: ({ row }) =>
      <>
        <Box title={ toGamel('view_type') }>View: { row.view_type }</Box> <Divider/>
        <Box title={ toGamel('furnish_type') }>Furnish: { row.furnish_type }</Box> <Divider/>
        <Box title={ toGamel('zone_type') }>Zone: { row.zone_type }</Box> <Divider/>
        <Box title={ toGamel('room_type') }>Room: { row.room_type }</Box>
      </>
    },
    // { field: "latitude", headerName: "Latitude", flex: 1, },
    // { field: "longitude", headerName: "Longitude", flex: 1, },
    // { field: "position", headerName: "Position", width: 120,
    //   renderCell: ({ row }) =>
    //   <Box lineHeight={1.4} display={'flex'} flexDirection={'column'} justifyContent={'center'} height="100%" gap={'10px'} sx={{ '& .pointer': { cursor: 'pointer' } }}
    //     onClick={ e => row.latitude && row.longitude && setGmap({ lat: row.latitude, lng: row.longitude, anchorEl: e.target, rowId: row.id }) }
    //     >
    //     <Button color={ gmap?.rowId === row.id ? "success" : "info" } sx={{ display: 'block', textAlign: 'left' }}>
    //       <Box title="Latitude">{ row.latitude }</Box>
    //       <Box title="Longitude">{ row.longitude }</Box>
    //     </Button>
    //   </Box>
    // },
    // { field: "date", headerName: "Created/Updated At", flex: 1,  width: '200px',
    //   renderCell: ({ row }) =>
    //   <>
    //     <span title={toGamel('created_at')}>{ toTime(row.created_at) }</span> <br/>
    //     <span title={toGamel('updated_at')}>{ toTime(row.updated_at) }</span> <br/>
    //   </>
    // },
    {
      field: "Associations", headerName: "Associations", width: 180, cellClassName: 'content-wrap',
      renderCell: ({ row, index, column }) => {
        const actions = [
          hasAccess && { name: 'Prices', icon: <PriceChange/> , color: 'warning', variant: 'filled', 
            handle: e => navigate(`/propertyPrices?property_id=${row.id}`) 
          },
          { name: 'Booking', icon: <BookOnline/> , color: 'success', variant: 'filled', 
            handle: e => navigate(`/booking-form?property_id=${row.id}`) 
          },
          hasAccess && { name: 'Payouts', icon: <BookOnline/> , color: 'secondary', variant: 'filled', 
            handle: e => setPayoutMonthAnchorEl(e.currentTarget)
          },
          { name: 'Electrics', icon: <ElectricBolt/> , color: 'primary', variant: 'filled', 
            handle: e => navigate(`/electric?property_id=${row.id}`) 
          }
        ].filter(Boolean);
        return (
          <Box lineHeight={1} display={'flex'} gap={1} flexWrap={'wrap'} justifyContent={'left'} sx={{ '& .pointer': { cursor: 'pointer' } }}>
            { actions.map(t => 
              <Chip key={t.name} icon={ t.icon } variant={ t.variant || "outlined" } color={t.color} label={t.name} size="small" sx={{ height: "22px" }} className="pointer" onClick={ t.handle } data-rowid={row.id}/>
            )}
          </Box>
        )
      }
    },
    {
      field: "actions", headerName: "Actions", width: 90, cellClassName: 'content-wrap',
      renderCell: ({ row, index, column }) => {
        const actions = [
          { name: 'Edit', icon: <EditOutlined/> , color: 'info', 
            handle: e => navigate(`/property-form?id=${row.id}`) 
          },
          { name: 'Del', icon: <DeleteOutline/> , color: 'error', 
            handle: e => handleRemove({ row, index, column }) 
          }
        ];
        return (
          <Box lineHeight={1} display={'flex'} gap={1} flexWrap={'wrap'} justifyContent={'left'} sx={{ '& .pointer': { cursor: 'pointer' } }}>
            { actions.map(t => 
              <Chip key={t.name} icon={ t.icon } variant={ t.variant || "outlined" } color={t.color} label={t.name} size="small" sx={{ height: "22px" }} className="pointer" onClick={ t.handle } data-rowid={row.id}/>
            )}
          </Box>
        )
      }
    },
  ])

  return (
    <Box m="20px">
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header
          title="PROPERTIES"
          subtitle="List of Properties for Future Reference"
          sx={{ marginBottom: 0, flex: 1 }}
          />
        
        { selectedRowIds.length > 0 && hasAccess &&
          // <Link to={ "/propertyPrices?property_ids=" + selectedRowIds.join(',') } style={{ textDecoration: "none" }}>
            <Button color="warning" variant="contained" sx={{ marginRight: "12px" }} onClick={ e => setPricesDialogShow(true) }>
              Set Prices
            </Button>
          // </Link>
        }

        <Box display={'flex'} gap={1} marginRight={2} flexWrap={'wrap'}>
          { Object.values(types).filter(Boolean).length > 0 && 
            <Button color="error" variant="outlined" onClick={ e => setTypes({}) }>Clear Filters ❌</Button> 
          }
          { typesFields.map(t => 
            <FormItem key={t.field} value={ types[t.field] } size="small" variant="outlined" label={ t.label } type="select" options={t.options} sx={{ width: '110px' }} name={t.field} onChange={t.onChange}/>
          )}
        </Box>

        { hasAccess &&
          <Link to="/property-form" style={{ textDecoration: "none" }}>
            <Button color="success" variant="contained" sx={{ marginRight: "12px" }} >
              Create Property
            </Button>
          </Link>
        }
      </Box>

      {message?.text && (
        <div className="mb-2 w-8/12">
          <Alert variant="filled" severity={ message.state || "error" }>
            {message.text}
          </Alert>
        </div>
      )}

      <MyTable isLoading={isLoading} paginate={paginate} columns={columns} rows={rows} 
        rowHeight={90}
        onSelect={ hasAccess ? handleRowSelect : null }
        onPageChange={page => setPaginate({ ...paginate, page })}
        />

      <Popover
        anchorEl={ gmap?.anchorEl }
        open={ Boolean(gmap) }
        onClose={ e => setGmap(null) }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ '& .MuiModal-backdrop': { backgroundColor: 'rgba(0, 0, 0, .3)' } }}
        >
        <GMapPicker
          hideSearch
          defaultLocation={gmap}
          styles={{ width: '30vw', height: '28vh' }}
        />
      </Popover>

      { previewImages.length ? <ImagePreview files={previewImages} onClose={ e => handleImagePreview([]) } currentIndex={0}/> : '' }

      <Popover
        anchorEl={ payoutMonthAnchorEl }
        open={ Boolean(payoutMonthAnchorEl) }
        onClose={ e => setPayoutMonthAnchorEl(null) }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ '& .MuiModal-backdrop': { backgroundColor: 'rgba(0, 0, 0, .3)' } }}
        >
        <Calendar
          format="YYYY-MM"
          onlyMonthPicker
          onChange={ hanlePayoutForm }
          className={ ['purple', theme.palette.mode == 'dark' && 'bg-dark'].join(' ') }
          />
      </Popover>
      
      <SettingPricesDialog propertyIds={ selectedRowIds } open={pricesDialogShow} onClose={ e => setPricesDialogShow(false) }/>
    </Box>
  );
};

export default Properties;
