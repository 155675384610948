import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Logout } from "@mui/icons-material";
import { logoutUser } from "../../store/auth/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import eventEmitter from 'src/eventEmitter';
import { TOPBAR_SEARCH_ON_EVENT } from 'src/eventEmitter/eventTypes';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };

  const searchRef = useRef(null);

  const handleSearch = (e) => {
    const keyword = searchRef.current?.querySelector('input')?.value
    eventEmitter.emit(TOPBAR_SEARCH_ON_EVENT, keyword)
  }

  const { pathname } = useLocation()

  // remove keyword when router change
  useEffect(() => {
    if (searchRef.current?.querySelector('input')) {
      searchRef.current.querySelector('input').value = ""
    }
  }, [pathname])

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" ref={searchRef} onKeyDown={ e => e.code === 'Enter' && handleSearch() }/>
        <IconButton type="button" sx={{ p: 1 }} onClick={ handleSearch } >
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
        <button
          onClick={handleLogout}
          className="flex items-center justify-center border border-red-500 hover:bg-red-500 hover:text-white text-red-500 font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          <Logout className="w-3 h-3 mr-2" />
          Logout
        </button>
      </Box>
    </Box>
  );
};

export default Topbar;
