// import HttpService from "./HttpService";
import axios from 'src/utils/axiosInstance';

const url = '/api/projects';

export const GetProjectService = (payload = {}) => {
  return axios.get(url, { params: payload })
};

export const GetEachProjectService = id => {
  if (!+id) return Promise.reject({ message: 'missing field: id' })
  return axios.get(`${url}/${id}`)
};

export const CreateProjectService = (payload = {}) => {
  if (!Object.keys(payload).length) return Promise.reject({ message: 'empty payload' })
  return axios.post(url, payload)
};
export const UpdateProjectService = ({ id, ...payload }) => {
  if (!+id) return Promise.reject({ message: 'missing field: { id }' })
  return axios.put(`${url}/${id}`, payload)
};

export const DeleteProjectService = id => {
  if (!+id) return Promise.reject({ message: 'missing field: id' })
  return axios.delete(`${url}/${id}`)
};