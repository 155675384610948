import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, Chip, useTheme, Alert, MenuItem, Stack, Typography } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from "@mui/lab/LoadingButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import SaveIcon from "@mui/icons-material/Save";
import Header from "src/components/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { tokens } from "src/theme";
import GMapPicker from "src/components/GMapPicker";
import FormItem from "src/pages/properties/formItem";
import useIsMobile from "src/hooks/useIsMobile";
import useLocationQuery from "src/hooks/useLocationQuery";
import { GetNews, UpdateNews, CreateNews } from "src/services/properties";

import { delay, toGamel } from "src/utils/Utils";

import { Save, StartOutlined } from "@mui/icons-material";

import Uploader from 'src/components/Uploader';
import axiosInstance from "src/utils/axiosInstance";
import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import useToast from "src/hooks/useToast";
import RichEditor from "src/components/RichEditor";
import useSelectFields from "./useSelectFields";
import useHasAccessByRole from "src/hooks/useHasAccessByRole";

const NewsForm = (props) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();

  const toast = useToast({ autoHideDuration: 2000 });

  const hasAccess = useHasAccessByRole()

  const [news, setNews] = useState(null);
  const [message, setMessage] = useState({ text: '', state: 'error' });

  const [formData, setFormData] = useState({
    title: '',
    short_description: '',
    long_description: '',
    content: '',
    news_type: '',
    news_status: '',
    audience_type: '',
    images: []
  });
  
  const { state: routerParams, ...params } = useLocation()
  const id = useLocationQuery('id', 'number');
  // const [loading, setLoading] = useState(!!+id);

  const { isLoading: loading, error, data, refetch } = useQuery({
    queryKey: ['newsFeeds', id],
    queryFn: _ => {
      return axiosInstance.get('/api/newsFeeds/' + id)
    },
    enabled: Boolean(id)
  })

  useEffect(() => {
    if (!id && routerParams?.news_type) {
      setFormData({...formData, news_type: routerParams.news_type})
    }
    if (id && !loading) { 
      if (data) {
        setNews({ ...data });
        Object.keys(formData).forEach(k => {
          formData[k] = data[k] || ''
        })
        setFormData({ ...formData });
      } else {
        setMessage({ text: 'News not found', state: 'error' })
      }
    }
    if (error) {
      setMessage({ text: error?.message || 'Get news Failed !', state: 'error' })
    }
  }, [id, loading, routerParams]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, formData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const mutation = useMutation({
    mutationFn: (fd) => {
      if (id && news) {
        return axiosInstance.put('/api/newsFeeds/' + id, fd)
      }
      return  axiosInstance.post('/api/newsFeeds', fd)
    }
  })

  useEffect(() => {
    if (mutation.isError) {
      toast(mutation.error?.message || 'request failed', -1)
    }
    if (mutation.isSuccess) {
      refetch()
      toast('success!', 1)
      delay(1500).then(_ => window.history.go(-1))
    }
  }, [mutation.isError, mutation.isSuccess])

  const submitActions = useMemo(() => [
    { label: 'Save To Draft', value: 'DRAFT', color: 'warning' },
    { label: 'Save To Pending', value: 'PENDING', color: 'secondary' },
    { label: 'Publish', value: 'PUBLISHED', color: 'success' },
  ].concat(
    id ? [{ label: 'Delete', value: 'DELETE', color: 'error' }] : []
  ), [id])

  
  const handleFormSubmit = async e => {
    e.preventDefault()

    formData.news_status = e.nativeEvent.submitter.name || submitActions[0].value

    // return console.log(e, formData);
    if (formData.news_status === 'DELETE') {
      if (window.confirm('Click Ok to confirm the deletion?')) {
        await axiosInstance.delete('/api/newsFeed/' + id)
        window.history.go(-1)
      }
      return 
    }

    if (mutation.isPending) {
      return toast('Submiting')
    };

    mutation.mutate(formData);
  }
  

  const selectFields = useSelectFields({ onFormChange: handleFormChange })

  const textFields = useMemo( 
    _ => Object.keys(formData).map(field => {
      const item = { type: 'text', field, required: false, label: toGamel(field), onChange: handleFormChange }
      if (field === 'long_description') {
        item.rows = 3
      }
      if ([...selectFields.map(f => f.field), 'content', 'images' ].includes(field)) {
        return false;
      }
      return item
    }).filter(Boolean), 
    [selectFields]
  )

  const boxProps = {
    mt: 3,
    display: 'flex', flexWrap: 'wrap', gap: '30px',
    width: isMobile ? '100%' : '70%',
    autoComplete: 'off',
    sx: {
      '& .MuiTextField-root': { m: 1, width: '40%' },
    }
  }

  return ( 
    <Box m="20px" pb="10px">
      <Box display={'flex'} alignItems={'center'} gap={4}>
        <Header
          title={`${news ? "UPDATE News" : "CREATE News"}`} mb={0}
          />
        { news?.news_status && 
          <Chip color={ submitActions.find(t => t.value === news.news_status)?.color } label={ news.news_status }/>
        }
      </Box>

      <div className="mb-4 w-8/12">
        {message.text && (
          <Alert variant="filled" severity={message.state}>
            {message.text}
          </Alert>
        )}
      </div>
      
      { !loading ? (
      <form onSubmit={handleFormSubmit}>

        { (!id || news) && 
          <Box { ...boxProps } display={'flex'} flexDirection={'column'} maxWidth={'60%'} m={1} mt={0} p={2} borderRadius={2} border={ `2px dashed ${colors.primary[200]}` }>
            <Typography variant='h5' lineHeight={1}>Poster And Images</Typography>
            <Uploader remotePath="/news" 
              disabled={ news?.news_status === 'PUBLISHED' }
              defaultValue={ news?.images ? news.images.map(t => ({ ...t, url: t.image_url })) : [] }
              removeOnServer={ !news?.id } // !dont remove image when news existed
              onChange={ files => setFormData({ ...formData, images: files.map(f => ({ id: f.serverId, image_url: f.serverUrl })) }) }
              onRemove={ file => !!file.serverImage?.news_feed_image } // return true: dont remove on server
            />
          </Box>
        }

        {/* description */}
        <Box { ...boxProps } sx={{
          '& .MuiTextField-root': { m: 1, width: 'calc(80% + 46px)' },
        }}>
          { textFields.map(item => <FormItem value={formData[item.field]} key={item.field} { ...item } disabled={ news?.news_status === 'PUBLISHED' }/>) }
        </Box>

        <Box { ...boxProps }>
          { selectFields.slice(0, 2).map(item => <FormItem value={formData[item.field]} key={item.field} type="select" { ...item } disabled={ news?.news_status === 'PUBLISHED' }/>) }
        </Box>

        {/* content */}
        <Box {...boxProps} sx={{
          '& .MuiTextField-root': { m: 1, width: 'calc(80% + 46px)' },
        }}>
          {/* TODO: ❗❗ hava content ?  */}
          {/* <RichEditor sx={{ width: 'calc(80% + 46px)' }} value={formData.content} onChange={v => handleFormChange({ target: { name: 'content', value: v } })}/> */}
          {/* <FormItem value={ formData.content } label={ 'Content' } multiline rows={15} field={'content'} onChange={ handleFormChange } /> */}
        </Box>
        
        <Box { ...boxProps } mt={4} mb={4} gap={2} justifyContent="start" >
          
          { hasAccess && (
              (news?.news_status === 'PUBLISHED' 
                ? submitActions.filter(v => v.value === 'DELETE')
                : submitActions
              ).map((t, i) => 
                <LoadingButton
                  key={t.value}
                  loading={mutation.isPending}
                  loadingPosition="start"
                  startIcon={<Save />}
                  type="submit"
                  name={ t.value }
                  color={ t.color }
                  variant="contained"
                  >
                  { t.label }
                </LoadingButton>
              )
            )
          }

          <Link to="/news" style={{ textDecoration: "none", marginLeft: '40px' }}>
            <Button color="info" variant="contained">Back </Button>
          </Link>
        </Box>
      </form>
      ) : (
        <Stack spacing={4} mt={4}>
          { Array(2).fill().map((_, i) => 
            <>
              <Skeleton key={i} variant="rectangular" width={300} height={50}/>
              <Skeleton key={i + 2} variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
            </>)
          }
          <Skeleton variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
          <Skeleton variant="rounded" width={isMobile ? '100%' : '70%'} height={'30vh'} />
        </Stack>
      )}
    </Box>
  );
};

export default NewsForm;
