import { useIsFetching, useQuery, useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import useToast from "src/hooks/useToast";
import axiosInstance from "src/utils/axiosInstance";

const url = '/api/property/prices';

export function usePriceAvailableStatus() {
  const query = useQuery({
    queryKey: ['property/prices/available_status'],
    queryFn: () => axiosInstance.get(`${url}/available_status`)
  })
  
  return query
}

export function usePricesByDateRange({ property_id, dateRange, available_status, period_type, sorting }, options = {}) {
  const queryKey = ['property/prices', property_id, dateRange, available_status, period_type, sorting];
  const query = useQuery({
    queryKey,
    queryFn: () => axiosInstance.get(url, { 
      params: {
        property_id: property_id,
        dateStart: dateRange[0],
        dateEnd: dateRange[1],
        ...(available_status ? { available_status } : {}),
        ...(period_type ? { period_type } : {}),
        ...(sorting ? { sorting } : {}),
      }
    }),
    enabled: Boolean(property_id && dateRange.length),
    ...options
  })

  const isFetching = useIsFetching({ queryKey })
  // const queryClient = useQueryClient()
  // const isFetching = queryClient.isFetching({ queryKey })
  // console.log(isFetching)

  return {
    ...query,
    queryKey,
    isFetching
  }
}

export function useFormSubmit(options) {
  const mutation = useMutation({
    mutationFn: ({ ...params }) => {
      if (params.hasOwnProperty('price')) {
        return axiosInstance.post(url, {
          ...params,
        })
      }
      if (params.hasOwnProperty('longPrice') && params.hasOwnProperty('shortPrice')) {
        const { shortPrice, longPrice } = params
        return Promise.all([
          axiosInstance.post(url, { 
            ...params,
            period_type: 'LONG', price: longPrice
          }),
          axiosInstance.post(url, {
            ...params,
            period_type: 'SHORT', price: shortPrice
          })
        ]).then(res => res.flat())
      }
    }
  })
  
  const toast = useToast()
  let onSuccess = e => toast('success', 1)
  let onError = e => toast(mutation.error?.message || 'request failed')

  if (options) {
    onSuccess = options.onSuccess || (e => null)
    onError = options.onError || (e => null)
  }

  useEffect(() => {
    if (mutation.isError) {
      onError()
    }
    if (mutation.isSuccess) {
      onSuccess()
    }
  }, [mutation.isError, mutation.isSuccess])

  return mutation
}