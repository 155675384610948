import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN,
  LOGOUT_USER,
} from "./actionTypes";
import {
  apiError,
  // authError,
  loginUserSuccessful,
  logoutUserSuccess,
} from "./actions";

import {
  LoginService,
} from "../../services/authServices";
import * as storage from 'src/utils/storage';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload }) {
  try {
    const response = yield call(LoginService, payload);
    // localStorage.setItem("ayattToken", JSON.stringify(response.data.token));
    // localStorage.setItem("ayattUser", JSON.stringify(response.data.user));
    storage.remove("ayattToken");
    storage.remove("ayattUser");
    if (payload.remember) {
      storage.local.set("ayattToken", response.token);
      storage.local.set("ayattUser", response.user);
    } else {
      storage.session.set("ayattToken", response.token);
      storage.session.set("ayattUser", response.user);
    }
    yield put(loginUserSuccessful(response));
    // history.push("/");
  } catch (error) {
    console.log(111, error);
    yield put(apiError(error?.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {

    // localStorage.removeItem("ayattToken");
    // localStorage.removeItem("ayattUser");
    storage.remove("ayattToken");
    storage.remove("ayattUser");

    yield put(logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}


export function* watchUserLogin() {
  yield takeEvery(LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* AuthSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
  ]);
}

export default AuthSaga;
