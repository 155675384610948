import { Box, Alert, useTheme, Button } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, Popover, TableRow, TableHead, TableFooter, Paper, Pagination } from "@mui/material"
import { Stack, Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector, useDispatch } from "react-redux";
import { fetchProject, fetchProjectError, setPage } from "../../store/project/actions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { toDate, toGamel, toTime } from "src/utils/Utils";
import GMapPicker from "src/components/GMapPicker";
import MyTable from "src/components/MyTable"
import useTableColumnsByRole from "src/hooks/useTableColumnsByRole";
import useHasAccessByRole from "src/hooks/useHasAccessByRole";

const Project = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const hasAccess = useHasAccessByRole();

  const [gmap, setGmap] = useState(null);

  const { loading, page, pageSize, total, projects, projectError } = useSelector(
    (state) => state.Project
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProject({ page, pageSize }));
  }, [dispatch, page]);

  useEffect(() => {
    if (projectError) {
      setTimeout(() => {
        dispatch(fetchProjectError(""));
      }, 2000);
    }
  }, [dispatch, projectError]);

  const handlePaginate = (e, p) => {
    dispatch(setPage(p))
  }

  const columns = useTableColumnsByRole([
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Project Name", flex: 1, cellClassName: "name-column--cell", },
    { field: "position", headerName: "Position", flex: 2,
      renderCell: ({ row }) =>
      <Box cursor="pointer" onClick={ e => setGmap({ lat: row.latitude, lng: row.longitude, anchorEl: e.target, rowId: row.id }) }>
        <Button color={ gmap?.rowId === row.id ? "success" : "info" } sx={{ display: 'block', textAlign: 'left' }}>
          <Box title="Latitude">{ row.latitude }</Box>
          <Box title="Longitude">{ row.longitude }</Box>
        </Button>
      </Box>
    },
    { field: "date", headerName: "Created At", flex: 1,  width: '200px',
      renderCell: ({ row }) =>
      <>
        <span title={toGamel('created_at')}>{ toTime(row.created_at) }</span> <br/>
        {/* <span title={toGamel('updated_at')}>{ toTime(row.updated_at) }</span> <br/> */}
      </>
    },
    { field: "actions", headerName: "Actions", flex: 1,
      renderCell: ({ row }) => 
      <Link to={`/project-form?id=${row.id}`} style={{ textDecoration: "none" }} >
        <Button variant="outlined" color="info" startIcon={<EditOutlinedIcon />}> Edit </Button>
      </Link>
    },
  ], 'projects');

  const rows = projects || []
  const paginate = {
    page, pageSize, total, pageCount: Math.ceil(total/pageSize)
  }

  return (
    <Box m="20px" gap={2}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2}>
        <Header title="PROJECTS" subtitle="List of all projects" sx={{ marginBottom: 0 }} />
        { hasAccess && 
          <Link to="/project-form" style={{ textDecoration: "none" }}>
            <Button
              color="secondary"
              variant="contained"
              sx={{ marginRight: "12px" }}
            >
              Create Project
            </Button>
          </Link>
        }
      </Box>
      {projectError && (
        <Alert variant="filled" severity="error">
          {projectError}
        </Alert>
      )}
      <MyTable isLoading={loading} paginate={paginate} columns={columns} rows={rows} autoHeight
        onPageChange={page => handlePaginate(undefined, page)}
        />

      <Popover
        anchorEl={ gmap?.anchorEl }
        open={ Boolean(gmap) }
        onClose={ e => setGmap(null) }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ '& .MuiModal-backdrop': { backgroundColor: 'rgba(0, 0, 0, .3)' } }}
        >
        <GMapPicker
          hideSearch
          defaultLocation={gmap}
          styles={{ width: '30vw', height: '28vh' }}
        />
      </Popover>
    </Box>
  );
};

export default Project;
