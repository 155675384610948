import { useIsFetching, useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import axiosInstance from "src/utils/axiosInstance";

const url = '/api/payments';

export function usePayments({ status }) {
  const [paginate, setPaginate] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    pageCount: 1
  })

  const query = useQuery({
    queryKey: ['payments', status, paginate.page],
    queryFn: () => {
      const { page, pageSize } = paginate;
      const useTypes = {}
      if (status) {
        useTypes.status = status
      }
      const useKeyword = {}//searchKeyword ? { keyword: searchKeyword } : {};
      return axiosInstance.get(url, { params: { ...useTypes, ...useKeyword, page, pageSize } })
    }
  })

  return {
    ...query,
    paginate,
    setPaginate,
  }
}

export function useFormSubmit({ onSuccess, onError }) {
  const mutation = useMutation({
    mutationFn: ({ id, ...formData }) => {
      if (id) {
        return axiosInstance.put(url + '/' + id, formData )
      }
      return axiosInstance.post(url, formData)
    }
  })

  useEffect(() => {
    if (mutation.isError) {
      onError()
    }
    if (mutation.isSuccess) {
      onSuccess()
    }
  }, [mutation.isError, mutation.isSuccess])

  return mutation
}