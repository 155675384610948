import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, TextField, useTheme, Alert, MenuItem, Stack } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Header from "../../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import GMapPicker from "src/components/GMapPicker";
import FormItem from "./formItem";
import useIsMobile from "src/hooks/useIsMobile";
import useLocationQuery from "src/hooks/useLocationQuery";
import { useProperty, useFormSubmit } from "src/services/properties";

import { delay, toGamel } from "src/utils/Utils";
import { useSelector } from "react-redux";
import useRemoteData from "src/hooks/useRemoteData";
import { StartOutlined } from "@mui/icons-material";

import Uploader from 'src/components/Uploader';
import axiosInstance from "src/utils/axiosInstance";
import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import useSelectFields from "./useSelectFields";
import useToast from "src/hooks/useToast";

const PropertyForm = () => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const isMobile = useIsMobile();

  const toast = useToast({ autoHideDuration: 2000 });

  const [property, setProperty] = useState(null);
  const [message, setMessage] = useState({ text: '', state: 'error' });
  const [submiting, setSubmiting] = useState(false);
  const [formData, setFormData] = useState({
    property_name: "",
    build_up_size: '',
    short_description: '',
    long_description: '',
    number_of_parking: '',
    meter_id: '',
    // number_of_rooms: '',
    number_of_toilets: '',
    unit_number: '',
    view_type: '',
    posted_date: '',
    zone_type: '',
    furnish_type: '',
    room_type: '',
    // latitude: '',
    // longitude: '',
    project_id: '',
    owner_id: '',
    images: []
  });

  const id = useLocationQuery('id', 'number');
  // const [loading, setLoading] = useState(!!+id);

  const { isLoading: loading, error, data, refetch } = useProperty({ id })

  useEffect(() => {
    if (id && !loading) { 
      if (data) {
        setProperty({ ...data });
        Object.keys(formData).forEach(k => {
          formData[k] = data[k] || ''
          if (k === 'imageIds') {
            formData[k] = data.images.map(t => t.id)
          }
        })
        setFormData({ ...formData });
      } else {
        setMessage({ text: 'Property not found', state: 'error' })
      }
    }
    if (error) {
      setMessage({ text: error?.message || 'Get Property Failed !', state: 'error' })
    }
  }, [id, loading]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePositionChange = ({ lat, lng, target }) => {
    if (target?.name) handleFormChange({ target })
    else setFormData(prevFormData => ({
      ...prevFormData,
      latitude: lat,
      longitude: lng
    }))
  }

  const mutation = useFormSubmit({
    onSuccess: () => {
      toast('success!', 1)
      refetch()
      delay(1500).then(_ => window.history.go(-1))
    },
    onError: () => {
      toast(mutation.error?.message || 'request failed', -1)
    }
  })

  const handleFormSubmit = async e => {
    e.preventDefault()
    // console.log(formData);

    if (mutation.isPending) {
      return toast('Submiting')
    };

    if (id) {
      formData.id = id
    }

    mutation.mutate(formData);
  }

  const selectFields = useSelectFields({ onFormChange: handleFormChange })
  
  const positionFields = []
  // ['latitude', 'longitude'].map(field => (
  //   { type: 'text', field, required: false, label: toGamel(field), value: formData[field], onChange: handlePositionChange }
  // ))

  const textFields = useMemo( 
    _ => Object.keys(formData).map(field => {
      const item = { type: 'text', field, required: true, label: toGamel(field), onChange: handleFormChange }
      if (field === 'long_description') {
        item.rows = 3
      }
      if (['number_of_parking', 'number_of_rooms', 'number_of_toilets'].includes(field)) {
        item.type = 'select'
        item.options = [1,2,3,4,5,6,7,8].map(p => ({ name: p, value: p }))
      }
      if (['posted_date'].includes(field)) {
        item.type = 'date'
      }
      if ([...selectFields.map(f => f.field), ...positionFields.map(f => f.field), 'images' ].includes(field)) {
        return false;
      }
      return item
    }).filter(Boolean), 
    [selectFields, positionFields]
  )

  const boxProps = {
    mt: 3,
    display: 'flex', flexWrap: 'wrap', gap: '30px',
    width: isMobile ? '100%' : '70%',
    autoComplete: 'off',
    sx: {
      '& .MuiTextField-root': { m: 1, width: '40%' },
    }
  }

  return ( 
    <Box m="20px" pb="10px">
      <Header
        title={`${property ? "UPDATE Property" : "CREATE Property"}`}
        subtitle={`${
          property ? "Update existing Property" : "Create a New Property"
        }`}
      />
      {/* <div className="mb-4 w-8/12">
        {message.text && (
          <Alert variant="filled" severity={message.state}>
            {message.text}
          </Alert>
        )}
      </div> */}
      
      { !loading ? (
      <form onSubmit={handleFormSubmit}>
        { (!id || property) && <Box { ...boxProps } display={'flex'} flexDirection={'column'} maxWidth={'60%'} m={1} mt={0} p={2} borderRadius={2} border={ `2px dashed ${colors.primary[200]}` }>
          <Typography variant='h5' lineHeight={1}>Poster And Images</Typography>
          <Uploader remotePath="/properties" 
            defaultValue={ property?.images ? property.images.map(t => ({ ...t, url: t.image_url })) : [] }
            removeOnServer={ !property?.id } // !dont remove image when property existed
            onChange={ files => setFormData({ ...formData, images: files.map(f => ({ id: f.serverId, image_url: f.serverUrl })) }) }
            onRemove={ file => !!file.serverImage?.property_image } // !return true: dont remove on server
          />
        </Box>
        }

        {/* name */}
        <Box { ...boxProps }>
          { textFields.slice(0, 2).map(item => <FormItem value={formData[item.field]} key={item.field} { ...item }/>) }
        </Box>

        {/* description */}
        <Box { ...boxProps } sx={{
          '& .MuiTextField-root': { m: 1, width: 'calc(80% + 46px)' },
        }}>
          { textFields.slice(2, 4).map(item => <FormItem value={formData[item.field]} key={item.field} { ...item }/>) }
        </Box>

        <Box { ...boxProps }>
          { textFields.slice(4).map(item => <FormItem value={formData[item.field]} key={item.field} { ...item }/>) }
        </Box>

        <Box { ...boxProps }>
          { selectFields.map(item => <FormItem value={formData[item.field]} key={item.field} select { ...item }/>) }
        </Box>

        <Box { ...boxProps }>
          { positionFields.map(item => 
            <FormItem  
              InputProps={{ autoComplete: 'off' }} 
              value={formData[item.field]} 
              key={item.field} 
              { ...item }
              />
          ) }
        </Box>
        
        {/* TODO: hidden ⛔ */}
        {/* <Box {...boxProps} mt={2}>
          <GMapPicker
            defaultLocation={property && { lat: property.latitude, lng: property.longitude }}
            onLocationChange={ handlePositionChange }
            onZoomChange={e => console.log(e)}
          />
        </Box> */}
        
        <Box { ...boxProps } mb={10} justifyContent="flex-end" >
          <LoadingButton
            loading={submiting}
            loadingPosition="start"
            startIcon={<SaveIcon/>}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {property ? "Update Property" : "Create New Property"}
          </LoadingButton>
          <Link to="/properties" style={{ textDecoration: "none" }}>
            <Button color="info" variant="contained">Back </Button>
          </Link>
        </Box>
      </form>
      ) : (
        <Stack spacing={4} mt={4}>
          { Array(2).fill().map((_, i) => 
            <>
              <Skeleton key={i} variant="rectangular" width={300} height={50}/>
              <Skeleton key={i + 2} variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
            </>)
          }
          <Skeleton variant="rectangular" width={isMobile ? '100%' : '70%'} height={50}/>
          <Skeleton variant="rounded" width={isMobile ? '100%' : '70%'} height={'30vh'} />
        </Stack>
      )}
    </Box>
  );
};

export default PropertyForm;
